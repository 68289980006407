import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import { FaMapMarker } from "react-icons/fa";
import { IoMapOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";

function RouteItemList({ route, goToMaps = true, goToShowRoute = false }) {
  const history = useHistory();

  const handleClickRota = (route) => {
    history.push(`/admin/default?rota=${route._id}`);
  };

  const handleClickShowRota = (route) => {
    history.push(`/admin/rotas/?rota=${route._id}`);
    window.location.reload()
  };


  let startName = '';
  let endName = '';
  try {
     startName = route.paths[0].name || '';
     endName = route.paths[route.paths.length - 1].name || '';
  } catch (error) {
  }
  return (
    <Card
      direction={{ base: "column", sm: "row" }}
      overflow="hidden"
      variant="outline"
      mb={3}
      mt={3}
    >
      <Stack>
        <Card>
          <Heading size="sm">
            {route.name}
            <Badge
              ml={5}
              p={2}
              height={6}
              alignItems={"center"}
              backgroundColor={"teal.400"}
              onClick={() => {
                handleClickRota(route);
              }}
              style={{ cursor: "pointer" }}
            >
              {route.units.length} unidades
            </Badge>
          </Heading>

          <Text>
            <b>Inicio:</b> {startName}
            <br />
            <b>Fim:</b> {endName}
          </Text>

          <Flex>
            {goToMaps && (
              <Box>
                <Button
                  onClick={() => {
                    handleClickRota(route);
                  }}
                  mt={3}
                  colorScheme={"teal"}
                  title="Link para a ficha do Google Meu Negócio"
                >
                  <Icon
                    w="24px"
                    h="24px"
                    me="5px"
                    color={"black.500"}
                    as={IoMapOutline}
                  />
                  Ir para o Mapa
                </Button>
              </Box>
            )}

            {goToShowRoute && (
              <Box ml={2}>
                <Button
                  onClick={() => {
                    handleClickShowRota(route);
                  }}
                  mt={3}
                  colorScheme={"teal"}
                  title="Link para a ficha do Google Meu Negócio"
                >
                  <Icon
                    w="24px"
                    h="24px"
                    me="5px"
                    color={"black.500"}
                    as={FaMapMarker}
                  />
                  Visualizar Rota
                </Button>
              </Box>
            )}
          </Flex>
        </Card>
      </Stack>
    </Card>
  );
}

export default RouteItemList;
