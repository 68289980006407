const LIMIT_POINT_ROUTES = 20;
const LIMIT_TIME_ROUTE = 20000;
const TIME_NEGOCIACAO = 15;
const OVERHEAD = 2;

export default (units, config ={ ignoreRoutes:false}) => {
  
  let timeTotal = 1;
  let totalDistance = 1;
  let timeDistance = 1;
  let timeNegociacao = 1;

  units = units.filter((unit) => {
    if(config.ignoreRoutes){
      unit.viewRoute = true
      return true
    }

    if (unit.routes && unit.routes.length > 0){
      unit.viewRoute = true
      return false
    }

    return true;
  });

  const responseUnits = units.map((unit, order) => {
    
   
    let unitTime = 0;
    let timeNegociacao = 0;
    
    let unitTimeDistance = unit.distance_lastpoint * 1000 * 0.5 + OVERHEAD;
    

    unitTime = unitTimeDistance + TIME_NEGOCIACAO;

    timeNegociacao += TIME_NEGOCIACAO;
    timeDistance += unitTimeDistance;

    timeTotal += unitTime;
    totalDistance += unit.distance_lastpoint;
    

    

    return {
      ...unit,
      order: order + 1,
    }
  });


  return {
    units: responseUnits,
    timeTotal,
    totalDistance,
    timeDistance,
    timeNegociacao,
  };
};
