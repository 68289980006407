import { Table, Tbody, Td, Th, Thead, Tr, Text, Alert, AlertIcon, UnorderedList, ListItem } from "@chakra-ui/react";
import { InfoIcon } from '@chakra-ui/icons';

import React, {useState} from "react";

function ContentMaturidadeDigital({ digitalMaturity }) {
	const scores = [
		{
			name: 'Responde às avaliações',
			value: digitalMaturity.infos.response_avaliacao,
			max: 1,
			info: 'Realizado cálculo de média com base nas ultimas 100 avaliações'
		},
		{
			name: 'Possui Site',
			value: digitalMaturity.infos.possui_site,
			max: 1,
		},
		{
			name: 'Tem Produtos cadastrados',
			value: digitalMaturity.infos.produto_cadastrado,
			max: 1,
		},
		{
			name: 'Média de avaliações',
			value: digitalMaturity.infos.media_avaliacao,
			max: 2,
			info: [
				<UnorderedList>
					<ListItem>5 estrelas [2]</ListItem>
					<ListItem>4,0 à 4,5 estrelas [1,5]</ListItem>
					<ListItem>3,0 à 3,5 estrelas [1]</ListItem>
					<ListItem>Abaixo de 3,5 [0,5]</ListItem>
					<ListItem>Sem avaliações [0]</ListItem>
				</UnorderedList>
			]
		},
		{
			name: 'Número de avaliações',
			value: digitalMaturity.infos.numero_avaliacao,
			max: 2,
			info: [
				<UnorderedList>
					<ListItem>Acima de 200 avaliações [2]</ListItem>
					<ListItem>100-200 avaliações [1,5]</ListItem>
					<ListItem>50-99 avaliações [1]</ListItem>
					<ListItem>Menos que 50 avaliações [0,5]</ListItem>
					<ListItem>Sem avaliações [0]</ListItem>
				</UnorderedList>
			]
		},
		{
			name: 'Não possui avaliações negativas',
			value: digitalMaturity.infos.avaliacao_negativas,
			max: 1,
		},
		{
			name: 'Nome do Estabelecimento',
			value: digitalMaturity.infos.nome_estabelecimento,
			max: 1,
			info: 'Análise manual realizado na análise guiada'
		},
		{
			name: 'Fachada atualizada',
			value: digitalMaturity.infos.fachada_atualizada,
			max: 1,
			info: 'Análise manual realizado na análise guiada'
		}
	]

	const [currentScoreDetail, setCurrentScoreDetail] = useState();
	const [iconInfoColor, setIconInfoColor] = useState([...Array(10).keys()]);

	const renderScores = () => {
		const allTableRow = [];

		for (const index in scores) {
			const score = scores[index];

			allTableRow.push(
				<Tr>
					<Td pl={0}>{score.name}</Td>
					<Td px='auto'>
						<Text fontWeight="bold">
							{score.value} / {score.max}

						</Text>
					</Td>

					{('info' in score) &&
						<Td pr={0} onClick={(event) => showScoreDetails(event, index)}>
							<InfoIcon color={iconInfoColor[index]}/>
						</Td>
					}
				</Tr>
			)
		}

		return allTableRow;
	}

	const showScoreDetails = (event, index) => {
		setCurrentScoreDetail(scores[index].info);
		setIconInfoColor(
			iconInfoColor.map((value, indexMap) => {
				if (index == indexMap) {
					return 'blue.500';
				}
		
				return 'blackAlpha.800';
			})
		);
	}

	return (
		<div>
			<Table>
				<Thead>
					<Tr>
						<Th></Th>
						<Th></Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{renderScores()}
				</Tbody>
			</Table>
			
			{currentScoreDetail &&
				<Alert status='info' mt={3} color='blue.900'>
					<AlertIcon />
					{currentScoreDetail}
				</Alert>
			}
		</div>
	);
}

export default ContentMaturidadeDigital;
