/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets

import illustration from "assets/img/auth/logo_nm_center.png";
import { MdOutlineRemoveRedEye, MdEmail, MdPhoneIphone } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useMask } from "@react-input/mask";
import { useHistory, useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";
import useForgotPassword from "repositories/useForgotPassword";
import { BiLoaderCircle } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPassword() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const useForgotPassword = useForgotPassword();

  const history = useHistory();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmmit = async e => {
    e.preventDefault();

    const data = {
      email
    };

    setIsProcessing(true)
    const response = await useResetPassword(data)
        .then(data => {
            setIsProcessing(false);
            toast.success(data.message)
        })
        .catch(error => {
            setIsProcessing(false);
            toast.error('Algo deu errado')
        });
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <ToastContainer />
        <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w='100%'
            mx={{ base: "auto", lg: "0px" }}
            me='auto'
            h='100%'
            alignItems='start'
            justifyContent='center'
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}
            flexDirection='column'>
            <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
                Recuperar Senha
            </Heading>

            </Box>
            <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            <FormControl>  
                <Flex gap={'8px'}>
                    <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Email<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                        name="email"
                        value={email}
                        isRequired={true}
                        variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='email'
                        placeholder='mail@monster.com'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        onChange={email => setEmail(email.target.value)}
                    />
                </Flex>
                <Flex justifyContent='space-between' align='center' mb='24px'>
                <NavLink to='/login'>
                    <Text
                    color={textColorBrand}
                    fontSize='sm'
                    w='124px'
                    fontWeight='500'>
                    Enviar
                    </Text>
                </NavLink>
                </Flex>
                {isProcessing
                ? <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'
                    disabled={true}
                    >
                    <BiLoaderCircle style = {{transform: 'rotate(90deg)' }} />
                    </Button>
                : <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'
                    onClick={handleSubmmit}
                    >
                    Salvar
                    </Button>
                }
            </FormControl>
            </Flex>
        </Flex>
    </DefaultAuth>
  );
}

export default ResetPassword;
