import {
  Box,
  Button,
  Flex,
  Grid,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Card from "components/card/Card";
import getMinutesVisita from "services/calcularDuracaoVisita";
import { IconError, IconSuccess } from "./icons";
import AnaliseGuiada from "./AnaliseGuiada";
import useGuidedAnalysisRepositoryPostGuidedAnalysis from "repositories/useGuidedAnalysisPostAnalysis";
import CardLoading from "./CardLoading";
import useUnitRepositoryGetUnit from "repositories/useUnitRepositoryGetUnit";

function ContentAnaliseGuiada({ unit }) {
  const createGuidedAnalysis = useGuidedAnalysisRepositoryPostGuidedAnalysis();
  const fetchUnit = useUnitRepositoryGetUnit();

  const [guidedAnalysis, setGuidedAnalysis] = useState(null);
  const [loading, setLoading] = useState(true);

  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.22)",
    "none"
  );

  const fetchGuidedAnalysisAndStoreState = async () => {
    setLoading(true);
    const unitData = await fetchUnit(unit._id);
    setGuidedAnalysis(unitData.guided_analysis);
    setLoading(false);
  };

  useEffect(async () => {
    await fetchGuidedAnalysisAndStoreState();
  }, []);

  const handleSalvarAnaliseGuiada = async ({ formState, visita }) => {
    setLoading(true);
    await createGuidedAnalysis(unit._id, {
      ...formState,
      usuario_id: 1,
    });
    await fetchGuidedAnalysisAndStoreState();
  };

  if (loading) {
    return <CardLoading />;
  }

  if (!guidedAnalysis) {
    return (
      <AnaliseGuiada
        visita={null}
        buttonSalvarAnaliseGuiada="Salvar Analise Guiada"
        handleSalvarAnaliseGuiada={handleSalvarAnaliseGuiada}
      />
    );
  }

  return (
    <Box boxShadow={shadow} borderRadius={5} marginTop={10}>
      <Card direction="column" w="100%">
        <Flex></Flex>
        <SimpleGrid columns={{ base: 2 }}>
          <Text margin={2} fontWeight={"bold"}>
            Data
          </Text>
          <Text margin={2}>
            {moment(guidedAnalysis.created_at).format("DD/MM/YYYY HH:mm")}
          </Text>

          <Text margin={2} fontWeight={"bold"}>
            Fachada Atualizada
          </Text>
          <Text margin={2}>
            {guidedAnalysis.fachada_atualizada ? IconSuccess : IconError}
          </Text>

          <Text margin={2} fontWeight={"bold"}>
            Nome da Empresa Condiz com a Ficha
          </Text>
          <Text margin={2}>
            {guidedAnalysis.nome_empresa_ficha_atualizada
              ? IconSuccess
              : IconError}
          </Text>

          <Text margin={2} fontWeight={"bold"}>
            Telefone Atualizado
          </Text>
          <Text margin={2}>
            {guidedAnalysis.telefone_atualizado ? IconSuccess : IconError}
          </Text>
        </SimpleGrid>
      </Card>
    </Box>
  );
}

export default ContentAnaliseGuiada;
