import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project imports
import { gridSpacing } from 'store/constant';

// assets
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';

// constant
const getInitialValues = (event, range) => {
    const newEvent = {
        nome: event && event.nome ? event.nome : '',
        email: event && event.email ? event.email : '',
        celular: event && event.celular ? event.celular : '',
        data_inicio: event ? new Date(event.start) : range ? new Date(range.start) : new Date(),
        data_fim: event ? new Date(event.end) : range ? new Date(range.end) : new Date(),
        unit_name: event && event.unit_name ? event.unit_name : '',
        unit_address: event && event.unit_address ? event.unit_address : '',
    };

    if (event || range) {
        return _.merge({}, newEvent, event);
    }

    return newEvent;
};

function addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes*60000);
}

// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

const AddEventFrom = ({ event, range, handleDelete, handleCreate, handleUpdate, onCancel }) => {
    const theme = useTheme();
    const isCreating = !event;
    const hasContato = event && event.nome;

    const backgroundColor = [
        {
            value: theme.palette.primary.main,
            color: 'primary.main',
            label: 'Default'
        },
        {
            value: theme.palette.error.main,
            color: 'error.main'
        },
        {
            value: theme.palette.success.dark,
            color: 'success.dark'
        },
        {
            value: theme.palette.secondary.main,
            color: 'secondary.main'
        },
        {
            value: theme.palette.warning.dark,
            color: 'warning.dark'
        },
        {
            value: theme.palette.orange.dark,
            color: 'orange.dark'
        },
        {
            value: theme.palette.grey[500],
            color: 'grey.500'
        },
        {
            value: theme.palette.primary.light,
            color: 'primary.light'
        },
        {
            value: theme.palette.error.light,
            color: 'error.light'
        },
        {
            value: theme.palette.success.light,
            color: 'success.light'
        },
        {
            value: theme.palette.secondary.light,
            color: 'secondary.light'
        },
        {
            value: theme.palette.warning.light,
            color: 'warning.light'
        },
        {
            value: theme.palette.orange.light,
            color: 'orange.light'
        }
    ];

    const textColor = [
        {
            value: theme.palette.error.light,
            color: 'error.light',
            label: ''
        },
        {
            value: theme.palette.success.light,
            color: 'success.light',
            label: ''
        },
        {
            value: theme.palette.secondary.light,
            color: 'secondary.light',
            label: ''
        },
        {
            value: theme.palette.warning.light,
            color: 'warning.light',
            label: ''
        },
        {
            value: theme.palette.orange.light,
            color: 'orange.light',
            label: ''
        },
        {
            value: theme.palette.primary.light,
            color: 'primary.light',
            label: ''
        },
        {
            value: theme.palette.primary.main,
            color: 'primary.main',
            label: ''
        },
        {
            value: theme.palette.error.main,
            color: 'error.main',
            label: ''
        },
        {
            value: theme.palette.success.dark,
            color: 'success.dark',
            label: ''
        },
        {
            value: theme.palette.secondary.main,
            color: 'secondary.main',
            label: ''
        },
        {
            value: theme.palette.warning.dark,
            color: 'warning.dark',
            label: ''
        },
        {
            value: theme.palette.orange.dark,
            color: 'orange.dark',
            label: ''
        },
        {
            value: theme.palette.grey[500],
            color: 'grey.500',
            label: ''
        }
    ];

    const EventSchema = Yup.object().shape({

    });

    function validarDiferencaDatas(data_inicio, data_fim) {
        // Converte as strings de data em objetos Date
        const inicio = new Date(data_inicio);
        const fim = new Date(data_fim);
      
        // Calcula a diferença em milissegundos entre as datas
        const diferencaEmMilissegundos = fim - inicio;
      
        // Converte a diferença para horas
        const diferencaEmHoras = diferencaEmMilissegundos / (1000 * 60 * 60);
      
        // Verifica se a diferença está entre 1 e 2 horas
        return (diferencaEmHoras >= 1 && diferencaEmHoras <= 2);
      }

    const formik = useFormik({
        initialValues: getInitialValues(event, range),
        validationSchema: EventSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                const data = {
                    data_inicio: values.data_inicio,
                    data_fim: values.data_fim
                };

                if (validarDiferencaDatas(data.data_inicio, data.data_fim)) {
                    if (event) {
                        handleUpdate(event.id, data);
                    } else {
                        handleCreate(data);
                    }

                    resetForm();
                    onCancel();
                    setSubmitting(false);
                } else {
                    toast.error("A reunião deve ter no mínimo 1 hora e no máximo 2 hora!");
                }
            } catch (error) {
                console.error(error);
            }
        }
    });

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <FormikProvider value={formik}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <DialogTitle>{event ? 'Editar Evento' : 'Adicionar Evento'}</DialogTitle>
                    <Divider />
                    <DialogContent sx={{ p: 3 }}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} md={6}>
                                <MobileDateTimePicker
                                    label="Inicio"
                                    value={values.data_inicio}
                                    inputFormat="dd/mm/yyyy hh:mm a"
                                    onChange={(date) => setFieldValue('data_inicio', date)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={Boolean(touched.data_inicio && errors.data_inicio)}
                                            helperText={touched.data_inicio && errors.data_inicio}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <DateRangeIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MobileDateTimePicker
                                    label="Fim"
                                    value={values.data_fim}
                                    inputFormat="dd/MM/yyyy hh:mm a"
                                    onChange={(date) => setFieldValue('data_fim', date)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={Boolean(touched.data_fim && errors.data_fim)}
                                            helperText={touched.data_fim && errors.data_fim}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <DateRangeIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            {/* Contato */}
                            {hasContato && <Grid item xs={12}>
                                <Typography>Contato</Typography>
                            </Grid>}
                            {hasContato && <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nome"
                                    {...getFieldProps('nome')}
                                    error={Boolean(touched.nome && errors.nome)}
                                    helperText={touched.nome && errors.nome}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>}
                            {hasContato && <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Celular"
                                    {...getFieldProps('celular')}
                                    error={Boolean(touched.celular && errors.celular)}
                                    helperText={touched.celular && errors.celular}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>}
                            {hasContato && <Grid item xs={12}>
                                <Typography>Unidade</Typography>
                            </Grid>
                            }
                            {hasContato && <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Nome da unidade"
                                    {...getFieldProps('unit_name')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>}
                            {hasContato && <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Endereço"
                                    {...getFieldProps('unit_address')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>}
                        </Grid>
                    </DialogContent>

                    <DialogActions sx={{ p: 3 }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                {!isCreating && (
                                    <Tooltip title="Delete Event">
                                        <IconButton onClick={() => handleDelete(event?.id)} size="large">
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Grid>
                            <Grid item>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Button type="button" variant="outlined" onClick={onCancel}>
                                        Cancelar
                                    </Button>
                                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                                        {event ? 'Editar' : 'Adicionar'}
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Form>
            </LocalizationProvider>
        </FormikProvider>
    );
};

AddEventFrom.propTypes = {
    event: PropTypes.object,
    range: PropTypes.object,
    handleDelete: PropTypes.func,
    handleCreate: PropTypes.func,
    handleUpdate: PropTypes.func,
    onCancel: PropTypes.func
};

export default AddEventFrom;
