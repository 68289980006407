import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import {SelectedLocationContext, CategoriesContext} from "contexts/prospeccao/IndexContext";

const initialFormState = {
  nome_empresa_ficha_atualizada: false,
  categoria_empresa_ficha_atualizada: false,
  fachada_atualizada: false,
  telefone_atualizado: false,
  porte_estabelecimento: "pequeno",
  nivel_estabelecimento: "1",
};

function AnaliseGuiada({
  visita,
  handleSalvarAnaliseGuiada,
  buttonSalvarAnaliseGuiada,
}) {
  const [formState, setFormState] = useState(initialFormState);
  const [showInputCategoria, setShowInputCategoria] = useState(true);

  const [selectedLocationContext, setSelectedLocationContext] = useContext(SelectedLocationContext);
  const categoriesContext = useContext(CategoriesContext);

  let buttonLabel =
    buttonSalvarAnaliseGuiada || "Salvar Analise Guiada e Iniciar Visita";

  useEffect(() => {}, []);

  const toggleInputCategoria = (event) => {
    const isInputCategoriaVisible = showInputCategoria;
    const target = event.target.id;

    setShowInputCategoria(!showInputCategoria);

    if (isInputCategoriaVisible) {
      delete formState.categoria;
      setFormState(formState);
    }

    handleChangeForm(event);
  };

  const isSwitchInput = (event) => {
    return event.target.className.includes("switch");
  };

  const handleChangeForm = (event) => {
    ;
    let target = event.target.id;
    let value = event.target.value;
    let checked = event.target.checked;

    setFormState({
      ...formState,
      ...{
        [target]: isSwitchInput(event) ? checked : value,
      },
    });
    
  };

  const handleClickSalvarAnaliseGuiada = async (event) => {
    event.preventDefault();
    ;
    await handleSalvarAnaliseGuiada({ formState, visita });
    updateCategoryOnUnitInformation();
  };

  const updateCategoryOnUnitInformation = () => {
    if (!('categoria' in formState)) {
      return;
    }

    const categoriaSelecionada = categoriesContext.filter(categoria => categoria.codigo_categoria_bm == formState.categoria)[0];
    
    setSelectedLocationContext({
      ...selectedLocationContext,
      category: categoriaSelecionada.nome
    })
  }

  const generateCategoryOptions = () => {
    let options = categoriesContext.map((option) => {
      if (option.nome != null)
        return (<option value={option.codigo_categoria_bm}>{option.nome}</option>)
    });

    options.unshift(<option disabled selected value="">Selecione uma categoria</option>);

    return options;

  }

  return (
    <div>
      <Text>
        Informe os dados referente a <b>Analise Guiada</b>
      </Text>

      <form onSubmit={handleClickSalvarAnaliseGuiada}>
        <hr style={{ margin: 10 }} />
        <Box>
          <FormControl width={"100%"} as={SimpleGrid} columns={{ base: 2 }}>
            <FormLabel htmlFor="nome_empresa_ficha_atualizada">
              Nome da Empresa Condiz com a Ficha
            </FormLabel>
            <Switch
              id="nome_empresa_ficha_atualizada"
              checked={formState.nome_empresa_ficha_atualizada}
              onChange={handleChangeForm}
            />
            <FormLabel htmlFor="categoria_empresa_ficha_atualizada">
              Categoria da empresa condiz com a Ficha
            </FormLabel>
            <Switch
              id="categoria_empresa_ficha_atualizada"
              checked={formState.categoria_empresa_ficha_atualizada}
              onChange={toggleInputCategoria}
            />
            {showInputCategoria &&
              <>
                <FormLabel htmlFor="categoria">
                  Nova Categoria
                </FormLabel>
                <Select
                  mb={2}
                  id="categoria"
                  value={formState.categoria}
                  onChange={handleChangeForm}
                  isRequired={true}
                  required="required"
                >
                  {generateCategoryOptions()}
                </Select>
              </>
            }
            <FormLabel htmlFor="fachada_atualizada">
              A fachada está atualizada?
            </FormLabel>
            <Switch
              id="fachada_atualizada"
              checked={formState.material_whatsapp}
              onChange={handleChangeForm}
            />
            <FormLabel htmlFor="telefone_atualizado">
              Telefone está atualizado?
            </FormLabel>
            <Switch
              id="telefone_atualizado"
              checked={formState.telefone_atualizado}
              onChange={handleChangeForm}
            />
            <FormLabel htmlFor="porte_estabelecimento">
              Porte Estabelecimento
            </FormLabel>
            <Select
              id="porte_estabelecimento"
              value={formState.porte_estabelecimento}
              onChange={handleChangeForm}
            >
              <option value="pequeno">P</option>
              <option value="medio">M</option>
              <option value="grande">G</option>
            </Select>

            <FormLabel htmlFor="nivel_estabelecimento">
              Nivel Estabelecimento
            </FormLabel>
            <Select
              id="nivel_estabelecimento"
              value={formState.nivel_estabelecimento}
              onChange={handleChangeForm}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Select>
          </FormControl>
        </Box>

        <Flex>
          <Button
            mt={"5"}
            mb={"5"}
            mx={"auto"}
            colorScheme={"green"}
            type="submit"
          >
            {buttonLabel}
          </Button>
        </Flex>
      </form>

    </div>
  );
}

export default AnaliseGuiada;
