import { Box } from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import GoogleMaps from "./GoogleMaps";

function CardGoogleMaps({
    location,
    unitsLocation,
    handleClickLocation,
    googleMapsRef,
    selectedRoute,
}) {
  return (
    <div>
      <Card align="center" direction="column" w="100%" p={2} pt={5}>
        <Box>
          <GoogleMaps
            handleClickLocation={handleClickLocation}
            locationUser={location}
            units={unitsLocation}
            googleMapsRef={googleMapsRef}
            route={selectedRoute}
            isProspeccao={true}
            centerMapProps={{
              lat: location.latitude,
              lng: location.longitude,
            }}
          />
        </Box>
      </Card>
    </div>
  );
}

export default CardGoogleMaps;
