export default function (unit) {
    let returnValue = {
        sum: 0,
        infos: {
            response_avaliacao: 0,
            possui_site: 0,
            produto_cadastrado: 0,
            media_avaliacao: 0,
            numero_avaliacao: 0,
            avaliacao_negativas: 0,
            nome_estabelecimento: 0,
            fachada_atualizada: 0
        }
    };
  
    if (unit.reviews_data) {
      returnValue.infos.response_avaliacao = ownAnswerReview(unit.reviews_data);
    }
    if (unit.site) {
      returnValue.infos.possui_site = 1;
    }
    if (unit.posts) {
      returnValue.infos.produto_cadastrado = 1;
    }
    if (unit.rating) {
      returnValue.infos.media_avaliacao = getScoreRatingAverage(unit.rating);
    }
    if (unit.reviews) {
      returnValue.infos.numero_avaliacao = getScoreRatingQuantity(unit.reviews);
    }
    if (unit.reviews_per_score) {
        returnValue.infos.avaliacao_negativas = hasNoNegativeFeedback(unit.reviews_per_score);
    }
    if (unit.guided_analysis) {
        if (unit.guided_analysis.nome_empresa_ficha_atualizada) {
            returnValue.infos.nome_estabelecimento = 1;
        }
        if (unit.guided_analysis.fachada_atualizada) {
            returnValue.infos.fachada_atualizada = 1;
        }
    }

    returnValue.sum = getScoreSum(returnValue.infos);

    return returnValue;
}

function getScoreRatingAverage(rating) {
    if (rating >= 5) {
        return 2;
    }
    else if (rating >= 4) {
        return 1.5;
    }
    else if (rating >= 3){
        return 1;
    }
    else if (rating > 0){
        return 0.5;
    }
    
    return 0
}

function getScoreRatingQuantity(reviews) {
    if (reviews > 200) {
        return 2;
    }
    else if (reviews >= 100) {
        return 1.5;
    }
    else if (reviews >= 50) {
        return 1;
    }
    else if (reviews > 0) {
        return 0.5;
    }

    return 0;

}

function hasNoNegativeFeedback(reviewsPerScore) {
    let scoreSum = 0;

    for (var score in reviewsPerScore) {
        scoreSum += reviewsPerScore[score];
    }

    if (scoreSum == 0 || reviewsPerScore['1'] > 0) {
        return 0
    }

    return 1
}

function getScoreSum(allScore) {
    let digitalMaturitySum = 0;

    for (var scoreName in allScore) {
        digitalMaturitySum += allScore[scoreName];
    }

    return parseFloat(digitalMaturitySum.toFixed(1));
}

function ownAnswerReview(allReview) {
    let qntReviewAnswered = 0;
    let score, percentage;

    for (var review of allReview) {
        if (review.owner_answer) {
            qntReviewAnswered++;
        }
    }

    percentage = ((qntReviewAnswered * 100) / allReview.length);
    score = (percentage * 0.01).toFixed(1);
    score = parseFloat(score);

    return score; 
}