import { Icon, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { IoStorefrontOutline } from "react-icons/io5";
import { greatPlaceStyle } from "./marker";
import { PointsRouteContext } from "contexts/prospeccao/IndexContext";

function UnitLocationMarker({
  location,
  handleClickLocation,
  id,
  ignoreRoute,
  mapUnitsSelected
}) {
  const [indexOf, setIndexOf] = useState(0);

  let style = { ...greatPlaceStyle, border: `5px solid #11047A` };
  let isPoint = false;
  let currentIgnoreRoute = ignoreRoute;

  if (location.viewRoute){
    currentIgnoreRoute = false;
  }

  if (location.visit.length > 0) {
    style = { ...style, border: `5px solid #fe9514` };
  }
  


  if (location.disabled) {
    style = {
      ...style,
      border: `5px solid #2f2f2f`,
      backgroundColor: "#9f9f9f",
      opacity: 0.1,
    };
  }

  if (location.routes.length > 0 && currentIgnoreRoute) {
    style = {
      ...style,
      border: `5px solid #2f2f2f`,
      backgroundColor: "#FC8181",
      opacity: 0.1,
    };
  }

  let currentUnit = location;
  if (mapUnitsSelected[location._id]) {
    currentUnit = mapUnitsSelected[location._id];
    isPoint = true;
  }

  if (isPoint) {
    style = { ...style, border: `5px solid #38B2AC` };
  }

  console.log(mapUnitsSelected)
  

  return (
    <div
      onClick={() => handleClickLocation(location._id)}
      className="hint hint--html hint--info hint--top"
      style={style}
    >
      {isPoint ? (
        <Text>
          <b>{currentUnit.order}</b>
        </Text>
      ) : (
        <Icon as={IoStorefrontOutline} />
      )}
    </div>
  );
}

export default UnitLocationMarker;
