// eslint-disable-next-line no-undef
const GOOGLE = google;

const getAddressByLatLng = async function (latlng) {
  return await new Promise((resolve, reject) => {
    const geocoder = new GOOGLE.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          resolve(results);
        } else {
          reject("No results found");
        }
      } else {
        reject("Geocoder failed due to: " + status);
      }
    });
  });
};

export default getAddressByLatLng;
