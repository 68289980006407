import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdMap,
  MdSearch,
  MdCalendarMonth
} from "react-icons/md";


import Proespeccao from "views/admin/prospeccao";
import RotasPage from "views/admin/rotas";
import AgendaPage from "views/admin/agenda";

const routes = [
  {
    name: "Rotas",
    layout: "/admin",
    path: "/rotas",
    icon: <Icon as={MdSearch} width="20px" height="20px" color="inherit" />,
    component: RotasPage,
    auth: true,
  },
  {
    name: "Prospecção",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdMap} width="20px" height="20px" color="inherit" />,
    component: Proespeccao,
    auth: true,
  },
  {
    name: "Agenda",
    layout: "/admin",
    path: "/agenda",
    icon: <Icon as={MdCalendarMonth} width="20px" height="20px" color="inherit" />,
    component: AgendaPage,
    auth: true,
  },
];

export default routes;
