import { Table, Tbody, Td, Tr, Text, Box, Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/react";
import DesirableScoreByNiche from "../../assets/constants/desirableScoreByNiche.json";
import React, {useState} from "react";

function ContentComparacaoConcorrentes({ unit }) {
	const formatter = new Intl.NumberFormat('pt-BR', { style: "decimal", maximumFractionDigits: 2 });

	const unitScore = {
		'avaliacao': unit.reviews ? Math.round(unit.reviews) : 0,
		'classificacao': unit.rating ? formatter.format(unit.rating) : "N/A",
		'respostas': formatter.format(unit.percentage_reviews_answered)
	}

	const desirableScore = unit.nicho ? DesirableScoreByNiche[unit.nicho.slug] : DesirableScoreByNiche["default"];

	const setColorByDesirable = (value, desirable) => {
		const colorGood = 'green.500';
		const colorBad = 'red.500';
		
		if (typeof(value) === 'string') {
			value = parseFloat(value.replace(",", "."));
		}
		
		if (typeof desirable === 'string') {
			return '';
		}

		if (value >= desirable) {
			return colorGood;
		}

		return colorBad;
	}

	const getScores = (type) => {
		if (!Object.keys(unit.competitors[type]).length) {
			return [];
		}

		const competitorsScore = {
			'avaliacao': Math.round(unit.competitors[type].average.quantity_evaluations),
			'classificacao': formatter.format(unit.competitors[type].average.average_ratings),
			'respostas': formatter.format(unit.competitors[type].average.percentage_reviews_answered)
		}
	
		const scores = [
			{
				title: 'Avaliação',
				name: 'Quantidade de Avaliações',
				value: unitScore['avaliacao'],
				valueCompetitors: competitorsScore['avaliacao'],
				valueDesirable: desirableScore.quantidade,
				unitBackgroundColor: setColorByDesirable(unitScore['avaliacao'], desirableScore.quantidade),
				competitorsBackgroundColor: setColorByDesirable(competitorsScore['avaliacao'], desirableScore.quantidade),
			},
			{
				title: 'Classificação',
				name: 'Classificação',
				value: unitScore['classificacao'],
				valueCompetitors: competitorsScore['classificacao'],
				valueDesirable: desirableScore.classificacao.join(' - '),
				unitBackgroundColor: setColorByDesirable(unitScore['classificacao'], desirableScore.classificacao[0]),
				competitorsBackgroundColor: setColorByDesirable(competitorsScore['classificacao'], desirableScore.classificacao[0]),
			},
			{
				title: 'Respostas',
				name: 'Avaliações respondidas',
				value: unitScore['respostas']+"%",
				valueCompetitors: competitorsScore['respostas']+"%",
				valueDesirable: typeof(desirableScore.resposta[0]) !== 'string' ? desirableScore.resposta.join('% - ')+"%" : desirableScore.resposta,
				unitBackgroundColor: setColorByDesirable(unitScore['respostas'], desirableScore.resposta[0]),
				competitorsBackgroundColor: setColorByDesirable(competitorsScore['respostas'], desirableScore.resposta[0]),
			}
		];

		return scores;
	}

	const renderScores = (type) => {
		const elementScore = [];
		const scores = getScores(type);

		for (const index in scores) {
			const score = scores[index];

			elementScore.push(
				<Box mb={5}>
					<Text fontSize='xl' fontWeight="bold" mb={2}>{score.title}</Text>

					<Table>
						<Tbody>
							<Tr>
								<Td px={1} py={2} border='1px'>{score.name}</Td>
								<Td px={1} py={2} border='1px' width='100px' textAlign='center' bg={score.unitBackgroundColor}>{score.value}</Td>
							</Tr>
							<Tr> 
								<Td px={1} py={2} border='1px'>Média da Concorrência</Td>
								<Td px={1} py={2} border='1px' width='100px' textAlign='center' bg={score.competitorsBackgroundColor}>{score.valueCompetitors}</Td>
							</Tr>
							<Tr>
								<Td px={1} py={2} border='1px'>Desejável</Td>
								<Td px={1} py={2} border='1px' width='100px' textAlign='center'>{score.valueDesirable}</Td>
							</Tr>
						</Tbody>
					</Table>
				</Box>
			)
		}

		if (elementScore.length) {
			return elementScore;
		}
		
		return [
			<Box my={50} textAlign="center" fontSize={"lg"} fontWeight={"bold"}>
				Nicho não encontrado
			</Box>
		]
	}

	return (
		<Tabs isFitted >
			<TabList mb='1em'>
				<Tab _selected={{ color: 'black', borderColor: 'black', bg: 'blackAlpha.50' }}>Nicho</Tab>
				<Tab _selected={{ color: 'black', borderColor: 'black', bg: 'blackAlpha.50' }}>Todos</Tab>
			</TabList>
			<TabPanels>
				<TabPanel>
					{renderScores('nicho')}
				</TabPanel>
				<TabPanel>
					{renderScores('todos')}
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
}

export default ContentComparacaoConcorrentes;
