import { useState, useEffect } from "react";
import { BASE_URL } from "services/baseurl";

const useGuidedAnalysisRepositoryPostGuidedAnalysis = () => {
  const fetchPostGuidedAnalysis = async (idUnit, guidedAnalysis) => {
    const url = `${BASE_URL}guided_analysis?unit_id=${idUnit}`;
    const response = await fetch(url, {
      method: "POST",
      body: guidedAnalysis,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(guidedAnalysis),
    });
    const responseJson = await response.json();

    return responseJson;
  };

  return fetchPostGuidedAnalysis;
};

export default useGuidedAnalysisRepositoryPostGuidedAnalysis;
