import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

import { HSeparator } from "components/separator/Separator";

import LogoHeader from "assets/img/dashboards/logo-header.png";

export function SidebarBrand() {
  return (
    <Flex align='center' direction='column'>
      <img  src={LogoHeader} style={{padding: 20, maxWidth: "70%"}} /> 
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
