import {
  Box,
  Button,
  Flex,
  Grid,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Card from "components/card/Card";
import getMinutesVisita from "services/calcularDuracaoVisita";
import { IconError, IconSuccess } from "./icons";
import CardVisita from "./CardVisita";

function NovaVisita({ visits, handleClickNovaVisita }) {
  return (
    <div>
      <Flex>
        <Button
          mt={"5"}
          mb={"5"}
          mx={"auto"}
          colorScheme={"green"}
          onClick={() => handleClickNovaVisita()}
        >
          Nova Visita
        </Button>
      </Flex>

      {visits.length > 0 && (
        <Box>
          <Flex>
            <Text m={"auto"} fontWeight={500}>
              Visitar no estabelecimento
            </Text>
          </Flex>
          <hr />
          {visits.map((visit, index) => (
            <div key={index}>
              <CardVisita visit={visit} />
            </div>
          ))}
        </Box>
      )}
    </div>
  );
}

export default NovaVisita;
