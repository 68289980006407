// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import calendarReducer from './slices/calendar';
import menuReducer from './slices/menu';
import businessMonsterReducer from './slices/bm';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    calendar: calendarReducer,
    menu: menuReducer,
    businessMonster: businessMonsterReducer
});

export default reducer;
