import { useState, useEffect } from "react";
import { BASE_URL } from "services/baseurl";

const useVisitRepositoryPostVisit = () => {
  const fetchPostVisit = async (idUnit, visit) => {
    const url = `${BASE_URL}visit?unit_id=${idUnit}`;
    const response = await fetch(url, {
      method: "POST",
      body: visit,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(visit),
    });
    const responseJson = await response.json();

    return responseJson;
  };

  return fetchPostVisit;
};

export default useVisitRepositoryPostVisit;
