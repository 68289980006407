import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Alert,
  Box,
  Button,
  Divider,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { PointsRouteContext } from "contexts/prospeccao/IndexContext";
import React, { useContext, useState } from "react";
import convertNumberToLetter from "services/numberToLetter";
import TabPontosParadas from "./TabPontosParadas";
import TabUnits from "./TabUnits";
import { UnitsRouteContext } from "contexts/prospeccao/IndexContext";
import { UnitsContext } from "contexts/prospeccao/IndexContext";
import calcularTempoRota from "services/calcularTempoRota";

function CardTablePointsRoutes({
  handleClickNewPoint,
  handleClickDeletePoint,
  handleRestartPosition
}) {
  const [pointsRoutes, setPointsRoutes] = useContext(PointsRouteContext);
  const [unitsRoute, setUnitsRoute] = useContext(UnitsRouteContext);
  const [unitsContext, setUnitsContext] = useContext(UnitsContext);

  const clickNewPoint = () => {
    handleClickNewPoint();
  };

  const clickDeletePoint = (point) => {
    handleClickDeletePoint(point);
  };

  const clickDeleteUnitRoute = (unit) => {
    const newUnits = unitsRoute.filter((el) => el._id !== unit._id);

    const {units } = calcularTempoRota(newUnits);

    setUnitsRoute(units);
  };

  return (
    <div>
      <Tabs variant="enclosed">
        <TabList>
          <Tab width={"200px"}>
            <Text fontSize="md" fontWeight="bold" textAlign={"center"} mb={5}>
              Pontos
            </Text>
          </Tab>
          <Tab
            width={"200px"}
            isDisabled={pointsRoutes.length === 0 ? true : false}
            _disabled={{
              color: "gray.500",
              cursor: "not-allowed",
              opacity: "0.5",
            }}
          >
            Unidades{" "}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TabPontosParadas
              pointsRoutes={pointsRoutes}
              clickNewPoint={clickNewPoint}
              clickDeletePoint={clickDeletePoint}
            />
          </TabPanel>
          <TabPanel>
            <TabUnits
              units={unitsRoute}
              clickDeletePoint={clickDeleteUnitRoute}
              handleRestartPosition={handleRestartPosition}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}

export default CardTablePointsRoutes;
