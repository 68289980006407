import { BASE_URL } from "services/baseurl";

const useVisitRepositoryGetOpenVisit = () => {
  const fetchGetOpenVisit = async (userId) => {
    const url = `${BASE_URL}visit/open?usuario_id=${userId}`;

    const response = await fetch(url);
    const responseJson = await response.json();
    return responseJson;
  };

  return fetchGetOpenVisit;
};

export default useVisitRepositoryGetOpenVisit;