import { Box, Flex, Spinner } from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";

function CardLoading({ box }) {
  return (
    <Box {...box}>
      <Card align="center" direction="column" w="100%">
        <Flex align="center" w="100%" px="15px" py="10px"></Flex>

        <Box mt="auto">
          <Flex>
            <Spinner
              fontSize={48}
              size={"xl"}
              margin={"auto"}
              height="100px"
              width={"100px"}
            />
          </Flex>
        </Box>
      </Card>
    </Box>
  );
}

export default CardLoading;
