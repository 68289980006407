// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { BASE_URL } from "services/baseurl";

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    events: []
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            console.log(state.error);
            console.log(action.payload);
            state.error = action.payload;
        },

        // GET EVENTS
        getEventsSuccess(state, action) {
            console.log(state.events);
            console.log(action.payload);
            state.events = action.payload;
        },

        // ADD EVENT
        addEventSuccess(state, action) {
            // console.log(action.payload);
            let aux = state.events
            aux.push(action.payload)
            state.events = aux;
        },

        // UPDATE EVENT
        updateEventSuccess(state, action) {
            state.events = action.payload;
        },

        // REMOVE EVENT
        removeEventSuccess(state, action) {
            state.events = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEvents() {
    return async () => {
        try {
            const url = `${BASE_URL}agenda/eventos`;
            const response = await fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            });
            const responseJson = await response.json();

            dispatch(slice.actions.getEventsSuccess(responseJson.eventos));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addEvent(event) {
    return async () => {
        try {
            const url = `${BASE_URL}agenda/eventos/novo`;
            await fetch(url, {
                method: 'POST',
                body: JSON.stringify(event),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateEvent(event) {
    console.log(event);
    return async () => {
        try {
            const url = `${BASE_URL}agenda/eventos/update/${event.eventId}`;
            await fetch(url, {
                method: 'POST',
                body: JSON.stringify(event.update),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeEvent(eventId) {
    return async () => {
        try {
            const url = `${BASE_URL}agenda/eventos/remove/${eventId}`;
            await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
