import { useState, useEffect } from "react";
import { BASE_URL } from "services/baseurl";

const useUnitRepositoryGetUnit = () => {
  return async (idUnit) => {
    const url = `${BASE_URL}units/${idUnit}`;
    const response = await fetch(url);
    const responseJson = await response.json();
    return responseJson;
  };
};

export default useUnitRepositoryGetUnit;
