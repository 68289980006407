import { useState, useEffect } from "react";
import { BASE_URL } from "services/baseurl";

const useVisitRepositoryGetVisits = (unitId) => {
  const fetchGetVisits = async (unitId) => {
    const url = `${BASE_URL}visit?unit_id=${unitId}`;

    const response = await fetch(url);
    const responseJson = await response.json();
    return responseJson;
  };

  return fetchGetVisits;
};

export default useVisitRepositoryGetVisits;
