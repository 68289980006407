import React, { useContext, useEffect, useRef, useState } from "react";

import { GOOGLE_MAPS_API_KEY } from "variables/googleMaps";
import MeLocationMarker from "./MeLocationMarker";
import UnitLocationMarker from "./UnitLocationMarker";
import {
  useUnitRepositoryGetUnitsByLatLong,
  useUnitRepositoryByPolygon,
} from "repositories/useUnitRepositoryGetUnits";
import {
  UnitsContext,
  GlobalLoadingContext,
} from "contexts/prospeccao/IndexContext";
import { Button, color, Flex, Icon, Spinner, Text, Switch, FormLabel } from "@chakra-ui/react";
import {
  useJsApiLoader,
  GoogleMap,
  OverlayView,
  Polygon,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { MapContext } from "contexts/prospeccao/IndexContext";
import { PointsRouteContext } from "contexts/prospeccao/IndexContext";
import { CenterLocationContext } from "contexts/prospeccao/IndexContext";
import { RouteDataContext } from "contexts/prospeccao/IndexContext";
import { UnitsRouteContext } from "contexts/prospeccao/IndexContext";
import { randomColor } from "@chakra-ui/theme-tools";
import { IoMap, IoMapOutline } from "react-icons/io5";

const GoogleMapsAllRoutes = ({ routes }) => {
  // eslint-disable-next-line no-undef
  const GOOGLE = google;

  const [map, setMap] = useContext(/** @type google.maps.Map */ (MapContext));
  const [centerMap, setCenterMap] = useState({
    lat: routes.length > 0 && routes[0].center.lat,
    lng: routes.length > 0 && routes[0].center.lng,
  });
  const [polygon, setPolygon] = useState([]);
  let [showInfoWindow, setShowInfoWindow] = useState(false);

  const handleMostraNomeRota = e => {
    setShowInfoWindow(e.target.checked);
  }


  const mapStyles = [
    {
      featureType: "all",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["geometry", "places", "visualization", "drawing"],
  });

  useEffect(() => {
    console.log(routes);
  }, [routes]);

  return (
    <div style={{ height: "70vh", width: "100%" }}>
      <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '8px' }}>
        <FormLabel htmlFor='mostra-nome-rota' mb='0'>
          Mostrar nome da rota
        </FormLabel>
        <Switch id='mostra-nome-rota' onChange={handleMostraNomeRota} />
      </div>
      <GoogleMap
        center={centerMap}
        zoom={16}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        options={{
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          styles: mapStyles,
        }}
        onLoad={(map) => {
          setMap(map);
        }}
      >
        {routes.length > 0 &&
          routes.map((item, index) => {
            const color = randomColor({
              luminosity: "light",
              hue: "blue",
            });

            return (
              <>
                <Polygon
                  key={index}
                  onLoad={() => {
                    console.log("polygon loaded");
                  }}
                  paths={
                    item.paths.map((path) => ({
                      lat: path.lat,
                      lng: path.lng,
                    })) || []
                  }
                  options={{
                    fillOpacity: 0.4,
                    strokeWeight: 3,
                    clickable: false,
                    editable: false,
                    zIndex: 1,
                    fillColor: color,
                  }}
                  onRightClick={() => {
                    console.log("polygon right click");
                  }}
                />
                <Marker
                  position={{ lat: item.center.lat,
                    lng: item.center.lng, }}
                >
                  {showInfoWindow && (
                    <InfoWindow>
                        <h4>{item.name}</h4>
                    </InfoWindow>
                )}
                </Marker>
              </>
            );
          })}
      </GoogleMap>
    </div>
  );
};
 
export default GoogleMapsAllRoutes;
