export default function (unit) {
  let returnValue = {
    infos: {
      horario_funcionamento: false,
      telefone: false,
      site: false,
      descricao: false,
      foto_capa: false,
      avaliacao: false,
    },
    possui: 0,
    naopossui: 0,
  };

  if (unit.working_hours) {
    returnValue.infos.horario_funcionamento = true;
  }
  if (unit.phone) {
    returnValue.infos.telefone = true;
  }
  if (unit.site) {
    returnValue.infos.site = true;
  }
  if (unit.description) {
    returnValue.infos.descricao = true;
  }
  if (unit.reviews) {
    returnValue.infos.avaliacao = true;
  }

  let possui = 0,
    naopossui = 0;
  Object.keys(returnValue.infos).forEach((el) => {
    if (returnValue.infos[el]) possui++;
    else naopossui++;
  });
  returnValue.possui = possui;
  returnValue.naopossui = naopossui;
  return returnValue;
}
