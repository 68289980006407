import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InputMask from 'react-input-mask';
import getMinutesVisita from "services/calcularDuracaoVisita";

const initialFormState = {
  nome_proprietario_visita: "",
  telefone_proprietario_visita: "",
  falou_proprietario: false,
  material_impresso: false,
  material_whatsapp: false,
  observacoes: "",
};

function VisitaEmCurso({ visita, handleFinalizarVisita }) {
  const [timeVisit, setTimeVisit] = useState("00:00");

  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    let minutes = getMinutesVisita(visita.data_inicio_visita);
    setTimeVisit(minutes);
    setInterval(() => {
      let minutes = getMinutesVisita(visita.data_inicio_visita);
      setTimeVisit(minutes);
    }, 1000);
  }, []);

  const isSwitchInput = (event) => {
    return event.target.className.includes("switch");
  };
  const handleChangeForm = (event) => {
    let target = event.target.id;
    let value = event.target.value;
    let checked = event.target.checked;
    setFormState({
      ...formState,
      ...{
        [target]: isSwitchInput(event) ? checked : value,
      },
    });
  };

  ;

  const handleClickFinalizarVisita = () => {
    handleFinalizarVisita({ formState, visita });
  };

  return (
    <div>
      <Text>Tempo de Inicio da Visita:</Text>
      <Flex>
        <Text
          fontSize={"48px"}
          fontWeight="bold"
          mx="auto"
          ps={{
            sm: "10px",
            xl: "16px",
          }}
          pt="18px"
          pb="12px"
        >
          {timeVisit}
        </Text>
      </Flex>

      <Box>
        <Text>Selecione as opções referente a essa Visita</Text>
        <hr style={{ margin: 10 }} />
        <Box>
          <FormControl width={"100%"} as={SimpleGrid} columns={{ base: 2 }}>
            <FormLabel htmlFor="nome_proprietario_visita">
              Nome do Proprietário
            </FormLabel>
            <Input
              mb={2}
              id="nome_proprietario_visita"
              value={formState.nome_proprietario_visita}
              onChange={handleChangeForm}
            />
            <FormLabel htmlFor="telefone_proprietario_visita">
              Telefone do Proprietário
            </FormLabel>
            <InputMask mask="(99) 99999-9999" alwaysShowMask={false} value={formState.telefone_proprietario_visita} id="telefone_proprietario_visita" onChange={handleChangeForm}>
              {(inputProps) => <Input mb={2} {...inputProps}/>}
            </InputMask>
            <FormLabel htmlFor="falou_proprietario">
              Falou com o Proprietário?
            </FormLabel>
            <Switch
              mb={2}
              id="falou_proprietario"
              checked={formState.falou_proprietario}
              onChange={handleChangeForm}
            />
            {/* TEMPORARIAMENTE REMOVIDO, FUTURAMENTE VOLTARÁ COM ESSE CAMPO */}
            {/* <FormLabel htmlFor="material_whatsapp">
              Material Enviado por WhatsApp
            </FormLabel>
            <Switch
              id="material_whatsapp"
              checked={formState.material_whatsapp}
              onChange={handleChangeForm}
            /> */}
            <FormLabel htmlFor="material_impresso">Material Impresso</FormLabel>
            <Switch
              mb={2}
              id="material_impresso"
              checked={formState.material_impresso}
              onChange={handleChangeForm}
            />
            <FormLabel htmlFor="observacoes">Observações</FormLabel>
            <Textarea
              mb={2}
              id="observacoes"
              value={formState.observacoes}
              onChange={handleChangeForm}
            />
          </FormControl>
        </Box>
      </Box>

      <Flex>
        <Button
          mt={"5"}
          mb={"5"}
          mx={"auto"}
          colorScheme={"red"}
          onClick={handleClickFinalizarVisita}
        >
          Finalizar Visita
        </Button>
      </Flex>
    </div>
  );
}

export default VisitaEmCurso;
