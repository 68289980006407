import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Flex, Icon, Input, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import GoogleMaps from "../GoogleMaps/GoogleMaps";
import WithoutRoutesPage from "./WithoutRoutesPage";
import { useRouteRepositoryGetRoutes } from "repositories/useRouteRepositoryRoutes";
import { useUnitRepositoryByPolygon } from "repositories/useUnitRepositoryGetUnits";
import CardTableRotasUnits from "./CardTableRotasUnits";
import { HiMap, HiPlus, HiSearch } from "react-icons/hi";
import { MapContext } from "contexts/prospeccao/IndexContext";
import { GlobalLoadingContext } from "contexts/prospeccao/IndexContext";
import CardTableRotasInformacoes from "./CardTableRotasInformacoes";
import ContentAvaliacoes from "../ContentAvaliacoes";
import ModalMoreInfoTable from "../ModalMoreInfoTable";
import CriarNovaRotaModal from "./CriarNovaRotaModal";
import RoutesPage from "./RoutesPage";
import CardLoading from "../CardLoading";
import RouteViewPage from "./RouteViewPage";
import { useHistory, useLocation } from "react-router-dom";
import {
  IoMapOutline,
  IoSave,
  IoSaveOutline,
  IoTrashOutline,
} from "react-icons/io5";
import RouteItemList from "./RouteItemList";
import CardTablePointsRoutes from "./CardTablePointsRoutes";
import { PointsRouteContext } from "contexts/prospeccao/IndexContext";
import { Autocomplete } from "@react-google-maps/api";
import ReactGoogleAutocomplete, {
  usePlacesWidget,
} from "react-google-autocomplete";
import { CenterLocationContext } from "contexts/prospeccao/IndexContext";
import calcularTempoRota from "services/calcularTempoRota";
import { UnitsContext } from "contexts/prospeccao/IndexContext";
import getAddressByLatLng from "services/getAddressByLatLng";
import { UnitsRouteContext } from "contexts/prospeccao/IndexContext";
import { AlertGlobalContext } from "contexts/prospeccao/IndexContext";
import { useUnitRepositoryGetUnitsByLatLong } from "repositories/useUnitRepositoryGetUnits";
import { useRouteRepositoryDeleteRoute } from "repositories/useRouteRepositoryRoutes";
import { useRouteRepositoryUpdateRoute } from "repositories/useRouteRepositoryRoutes";
import DeleteNovaRotaModal from "./DeleteNovaRotaModal";
import AllMapRoutesPage from "./AllMapRoutesPage";

// eslint-disable-next-line no-undef
const GOOGLE = google;

function setError(error) {
  console.error(error);
}

function CardGoogleMapsRotas({ unitsLocation, route }) {
  const [routes, setRoutes] = useState([]);
  const getRoutes = useRouteRepositoryGetRoutes();
  const getUnits = useUnitRepositoryByPolygon();
  const getUnitsByLatLng = useUnitRepositoryGetUnitsByLatLong();

  const routeDelete = useRouteRepositoryDeleteRoute();
  const routeUpdate = useRouteRepositoryUpdateRoute();

  const [map, setMap] = useContext(MapContext);
  const [typeView, setTypeView] = useState("ROUTES");
  const [mapStep, setMapStep] = useState("STEP_01");
  const [globalLoading, setGlobalLoading] = useContext(GlobalLoadingContext);
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState({ id: null, visible: false });
  const [routeView, setRouteView] = useState(null);
  const [routePoints, setRoutePoints] = useContext(PointsRouteContext);
  const [centerLocation, setCenterLocation] = useContext(CenterLocationContext);
  const [unitsContext, setUnitsContext] = useContext(UnitsContext);
  const [unitsRoute, setUnitsRoute] = useContext(UnitsRouteContext);
  const [alertGlobal, setAlertGlobal] = useContext(AlertGlobalContext);
  const [unitRouteMap, setUnitRouteMap] = useState({});
  const browserLocation = useLocation();
  const history = useHistory();

  const [selectedRoute, setSelectedRoute] = useState(null);

  const MODAL_IDS = {
    newRota: {
      title: "Detalhes",
      content: () => (
        <CriarNovaRotaModal
          units={unitsLocation}
          route={selectedRoute}
          handleClose={handleCloseModal}
        />
      ),
    },
    deleteRota: {
      title: "Excluir Rota",
      content: () => (
        <DeleteNovaRotaModal route={routeView} handleClose={handleCloseModal} />
      ),
    },
  };

  const handleCloseModal = () => {
    setModalState({ visible: false, id: null });
  };

  const GoogleMapsRef = useRef(null);

  const [location, setLocation] = useState({
    latitude: -23.581988,
    longitude: -46.6520874,
  });

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError("Geolocation is not supported");
      return;
    }
    let watcher = geo.watchPosition(function (position) {
      let newLocations = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      setLocation(newLocations);
    });
    return () => geo.clearWatch(watcher);
  }, []);

  useEffect(async () => {
    setLoading(true);
    let routes = await getRoutes(4); ///
    setRoutes(routes);
    setLoading(false);
  }, []);

  useEffect(async () => {
    let search = new URLSearchParams(browserLocation.search);
    let routeId = search.get("rota");

    if (!routeId && typeView === "MAP") return;

    const route = routes.find((route) => route._id === routeId);

    if (!route) return;

    setCenterLocation(route.center);
    setRouteView(route);
    setTypeView("MAP");

    if (
      routeId &&
      route &&
      GoogleMapsRef.current &&
      GoogleMapsRef.current.passRoute
    ) {
      await GoogleMapsRef.current.passRoute(route);
    }
  }, [GoogleMapsRef.current, routes]);

  const handleClickNewRotas = () => {
    setTypeView("MAP");
  };

  const handleClickCriarRota = async () => {
    handleClickModal("newRota");
  };

  const handleClickModal = (idModal) => {
    let modal = MODAL_IDS[idModal];
    if (!modal) return;

    setModalState({ visible: true, id: idModal });
  };

  const handleChangeCalculateRoute = (route) => {
    setSelectedRoute(route);
  };

  const isRouteSelected = () => {
    return typeView === "MAP" && routeView;
  };

  const fetchDefaultUnits = async () => {
    try {
      let center = { lat: map.getCenter().lat(), lng: map.getCenter().lng() };
      GoogleMapsRef.current.setIsLoadingMaps(true);
      const units = await getUnitsByLatLng(4, center.lat, center.lng);
      GoogleMapsRef.current.setIsLoadingMaps(false);
      setUnitsContext(units);
    } catch (error) {
      console.log(error);
      setUnitsContext([]);
      setAlertGlobal({
        visible: true,
        message: "Não foi possível calcular a rota. Poligono Invalído.",
        type: "error",
        time: 5000,
      });
      GoogleMapsRef.current.setIsLoadingMaps(false);
    }
  };

  const handleClickSearchPlaces = async () => {
    try {
      if (routePoints.length === 0) {
        return fetchDefaultUnits();
      }

      let pointsPolygon = [...routePoints];
      pointsPolygon[pointsPolygon.length] = pointsPolygon[0];

      GoogleMapsRef.current.setIsLoadingMaps(true);
      const unitsByPolygon = await getUnits(4, pointsPolygon);
      GoogleMapsRef.current.setIsLoadingMaps(false);

      const { units } = calcularTempoRota(unitsByPolygon);

      setUnitsContext(units);

      console.log("units route", units);
      setUnitsRoute(units);
    } catch (error) {
      console.log(error);
      setUnitsRoute([]);
      setAlertGlobal({
        visible: true,
        message: "Não foi possível calcular a rota. Poligono Invalído.",
        type: "error",
        time: 5000,
      });
      GoogleMapsRef.current.setIsLoadingMaps(false);
    }
  };

  const handleRestartPosition = async () => {
    GoogleMapsRef.current.setIsLoadingMaps(true);

    let pointsPolygon = [...routePoints];
    pointsPolygon[pointsPolygon.length] = pointsPolygon[0];

    const unitsByPolygon = await getUnits(4, pointsPolygon);

    const { units } = calcularTempoRota(unitsByPolygon, { ignoreRoutes: true });

    setUnitsContext(units);

    console.log("units route", units);
    GoogleMapsRef.current.setIsLoadingMaps(false);

    setUnitsRoute(units);
  };

  const handleClickDeleteRoute = (route) => {
    handleClickModal("deleteRota");
  };

  const handleClickUpdateRoute = async (route) => {
    try {
      GoogleMapsRef.current.setIsLoadingMaps(true);

      await routeUpdate(4, route._id, {
        paths: routePoints,
        center: {
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng(),
        },
        units: unitsRoute.map((unit) => unit._id),
      });

      setAlertGlobal({
        visible: true,
        message: "Rota atualizada com sucesso.",
        type: "success",
        time: 5000,
      });
      GoogleMapsRef.current.setIsLoadingMaps(false);
    } catch (error) {
      console.log(error);

      setAlertGlobal({
        visible: true,
        message: "Não foi possível atualizar a rota.",
        type: "error",
        time: 5000,
      });
    }
  };

  const handleClickViewAllRotasMap = () => {
    window.location.href = "/#/admin/rotas";
    setTypeView("ALL_ROUTES");
    setRouteView(null);
    setSelectedRoute(null);

  };

  const handleClickListagemRotas = () => {
    window.location.href = "/#/admin/rotas";
    setTypeView("ROUTES");
    setRouteView(null);
    setSelectedRoute(null);
  };

  const handleClickNewPoint = async () => {
    let center = { lat: map.getCenter().lat(), lng: map.getCenter().lng() };

    const latlng = {
      lat: center.lat,
      lng: center.lng,
    };

    GoogleMapsRef.current.setIsLoadingMaps(true);
    const address = await getAddressByLatLng(latlng);
    center = {
      ...center,
      name: address[0].formatted_address || "Endereço não encontrado",
    };
    GoogleMapsRef.current.setIsLoadingMaps(false);

    setRoutePoints([...routePoints, center]);
  };

  const handleAddPolygonInMaps = async (polygon) => {
    const latitudeLongitudePoints = JSON.parse(
      JSON.stringify(polygon.getPath().getArray())
    );
    const promissesAddress = latitudeLongitudePoints.map((point) => {
      return getAddressByLatLng(point);
    });

    const address = await Promise.all(promissesAddress);

    const points = address.map((address, index) => {
      return {
        lat: latitudeLongitudePoints[index].lat,
        lng: latitudeLongitudePoints[index].lng,
        name: address[0].formatted_address || "Endereço não encontrado",
        polygon: true,
      };
    });

    setRoutePoints(points);
  };

  const handleClickDeletePoint = async (point) => {
    console.log(point);
  };

  const calculateRoute = async (route) => {};

  const handleClickRota = (route) => {
    history.push(`/admin/default?rota=${route._id}`);
  };

  const isShowRoute = () => {
    return !(routeView === null);
  };

  if (loading) return <CardLoading />;

  if (routes.length === 0 && typeView !== "MAP")
    return <WithoutRoutesPage handleClickNewRotas={handleClickNewRotas} />;

  if (routes.length > 0 && typeView === "ROUTES")
    return (
      <RoutesPage
        handleClickNewRotas={handleClickNewRotas}
        routes={routes}
        handleClickViewAllRotasMap={handleClickViewAllRotasMap}
      />
    );

  if (typeView === "ALL_ROUTES" && routes.length > 0)
    return (
      <AllMapRoutesPage
        handleClickNewRotas={handleClickNewRotas}
        handleClickListagemRotas={handleClickListagemRotas}
        routes={routes}
      />
    );

  const renderCreateRouteBar = () => {
    return (
      <Box>
        <Button
          title="Buscar"
          backgroundColor={"teal.400"}
          onClick={handleClickSearchPlaces}
        >
          <Icon w="24px" h="24px" me="5px" color={"white"} as={HiSearch} />
          <Text color={"white"}>Buscar Estabelecimentos</Text>
        </Button>
        <Button
          title="Buscar"
          backgroundColor={"teal.400"}
          ml={5}
          onClick={handleClickCriarRota}
          disabled={globalLoading || unitsLocation.length === 0}
        >
          <Icon w="24px" h="24px" me="5px" color={"white"} as={HiPlus} />
          <Text color={"white"}>Criar rota</Text>
        </Button>
        <Text color={"grey.700"} fontWeight={"bold"}></Text>
      </Box>
    );
  };

  const renderShowRouteBar = () => {
    return (
      <Box>
        <Button
          onClick={() => {
            handleClickRota(routeView);
          }}
          m={3}
          colorScheme={"teal"}
          title="Link para a ficha do Google Meu Negócio"
        >
          <Icon
            w="24px"
            h="24px"
            me="5px"
            color={"black.500"}
            as={IoMapOutline}
          />
          Ir para o Mapa
        </Button>

        {isRouteSelected() && (
          <>
            <Button
              colorScheme={"teal"}
              title="Salvar as alterações da rota"
              onClick={() => {
                handleClickUpdateRoute(routeView);
              }}
            >
              <Icon
                w="24px"
                h="24px"
                me="5px"
                color={"black.500"}
                as={IoSaveOutline}
              />
              Salvar Alterações
            </Button>
            <Button
              colorScheme={"red"}
              m={3}
              title="Excluir a rota"
              onClick={() => {
                handleClickDeleteRoute(routeView);
              }}
            >
              <Icon
                w="24px"
                h="24px"
                me="5px"
                color={"black.500"}
                as={IoTrashOutline}
              />
              Excluir Rota
            </Button>
          </>
        )}
      </Box>
    );
  };

  const renderCreateTopBar = () => {
    return (
      <Card mb={5} mt={5}>
        <Text color={"grey.700"} fontWeight={"bold"} className="mb-3 mt-2">
          Informe um endereço para o ponto de inicio da rota
        </Text>
        <Flex padding={5}>
          <ReactGoogleAutocomplete
            style={{ width: "100%" }}
            placeholder="Endereço"
            options={{
              types: ["address"],
            }}
            onPlaceSelected={(place) => {
              setCenterLocation({
                ...place,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              });
            }}
          />
        </Flex>
      </Card>
    );
  };

  const handleClickRemovePoint = (point) => {
    let newRoutes = routePoints.filter(
      (route) => route.lat !== point.lng && route.lng !== point.lng
    );
    newRoutes = newRoutes.filter((route) => !route.polygon);

    console.log(routeView);
    if (routeView?._id) {
      return setAlertGlobal({
        visible: true,
        message: "Não é possível remover pontos de uma rota salva.",
        type: "error",
        time: 5000,
      });
    }

    if (newRoutes.length === 0) {
      setRoutePoints([]);
      setUnitsRoute([]);
      GoogleMapsRef.current.setPolygon([]);
      fetchDefaultUnits();
      return;
    }

    setRoutePoints(newRoutes);
  };

  const handleClickLocation = (unitId) => {
    console.log(unitId);
  };

  const renderShowTopBar = () => {
    return <RouteItemList route={routeView} goToMaps={false} />;
  };
  
  return (
    <Box>
      <Box
        p="40px"
        color="white"
        mt="4"
        bg="teal.500"
        rounded="md"
        shadow="md"
        mb={5}
        display="flex"
      >
        <Text fontSize="xl" fontWeight="bold">
          Rotas do Sistema
        </Text>

        <Button
          colorScheme={"purple"}
          style={{ position: "absolute", right: 100 }}
          onClick={handleClickListagemRotas}
        >
          <Text fontSize="xl" fontWeight="bold">
          Listagem de Rotas
          </Text>
        </Button>

        
      </Box>
      <Box>{isShowRoute() ? renderShowTopBar() : renderCreateTopBar()}</Box>

      <Card direction="column" w="100%" p={2} pt={5}>
        <Flex>
          <GoogleMaps
            googleMapsRef={GoogleMapsRef}
            handleClickLocation={handleClickLocation}
            locationUser={location}
            centerMapProps={centerLocation}
            units={unitsLocation}
            handleChangeCalculateRoute={handleChangeCalculateRoute}
            calculateRoute={calculateRoute}
            markerCenter={isShowRoute() ? false : true}
            handleAddPolygonInMaps={handleAddPolygonInMaps}
            route={routeView}
          />

          <CardTablePointsRoutes
            handleRestartPosition={handleRestartPosition}
            handleClickNewPoint={handleClickNewPoint}
            handleClickDeletePoint={handleClickRemovePoint}
          />
        </Flex>
      </Card>

      <Card mb={5} mt={5} p={5}>
        {isShowRoute() ? renderShowRouteBar() : renderCreateRouteBar()}
      </Card>

      <CardTableRotasInformacoes units={unitsRoute} routePoints={routePoints} />

      <CardTableRotasUnits units={unitsRoute} />

      {modalState.visible && (
        <ModalMoreInfoTable
          isOpen={modalState.visible}
          onClose={handleCloseModal}
          title={MODAL_IDS[modalState.id].title}
          content={MODAL_IDS[modalState.id].content}
        />
      )}
    </Box>
  );
}

export default CardGoogleMapsRotas;
