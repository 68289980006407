import {
  Box,
  Button,
  Flex,
  Grid,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Card from "components/card/Card";
import getMinutesVisita from "services/calcularDuracaoVisita";
import { IconError, IconSuccess } from "./icons";

function CardVisita({ visit }) {
  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.22)",
    "none"
  );

  ;
  return (
    <Box boxShadow={shadow} borderRadius={5} marginTop={10}>
      <Card direction="column" w="100%">
        <Flex>
          <Text fontSize={24} fontWeight={"bold"} margin={5} mx={"auto"}>
            VISITA #{moment(visit.data_inicio_visita).format("DDMMYYYYHHmm")}
          </Text>
        </Flex>
        <SimpleGrid columns={{ base: 2 }}>
          <Text margin={2} fontWeight={"bold"}>
            Data
          </Text>
          <Text margin={2}>
            {visit.data_inicio_visita &&
              moment(visit.data_inicio_visita).format("DD/MM/YYYY HH:mm")}
          </Text>
          <Text margin={2} fontWeight={"bold"}>
            Tempo da Visita
          </Text>
          <Text margin={2}>
            {getMinutesVisita(visit.data_inicio_visita, visit.data_fim_visita)}
          </Text>

          <Text margin={2} fontWeight={"bold"}>
            Nome do Proprietario
          </Text>
          <Text margin={2}>{visit.nome_proprietario_visita}</Text>

          <Text margin={2} fontWeight={"bold"}>
            Telefone Proprietario
          </Text>
          <Text margin={2}>{visit.telefone_proprietario_visita}</Text>

          <Text margin={2} fontWeight={"bold"}>
            Falou com Proprietario
          </Text>
          <Text margin={2}>
            {visit.falou_proprietario ? IconSuccess : IconError}
          </Text>

          <Text margin={2} fontWeight={"bold"}>
            Material Impresso
          </Text>
          <Text margin={2}>
            {visit.material_impresso ? IconSuccess : IconError}
          </Text>

          <Text margin={2} fontWeight={"bold"}>
            Material WhatsApp
          </Text>
          <Text margin={2}>
            {visit.material_whatsapp ? IconSuccess : IconError}
          </Text>

          <Text margin={2} fontWeight={"bold"}>
            Observações
          </Text>
          <Text margin={2}>{visit.observacoes}</Text>
        </SimpleGrid>
      </Card>
    </Box>
  );
}

export default CardVisita;
