import { Box, Button, Icon, Text } from '@chakra-ui/react'
import Card from 'components/card/Card'
import React from 'react'

function WithoutRoutesPage({handleClickNewRotas}) {
  return (
    <Box >
    <Card align="center" direction="column" w="100%" p={2} pt={5}>
      <Box>
        <Text color={'grey.700'} fontWeight={'bold'}> Não há rotas registadas no sistema</Text>
        <br/>
        <Button
            bg={'green.400'}
            onClick={() => {handleClickNewRotas()}}>
            Nova Rota
          </Button>
      </Box>
    </Card>
  </Box>
  )
}

export default WithoutRoutesPage