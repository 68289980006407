/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================
* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)
* Designed and Coded by Simmmple
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/logo_nm_center.png";
import { MdOutlineRemoveRedEye, MdEmail, MdPhoneIphone } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useMask } from "@react-input/mask";
import { useHistory, useParams } from "react-router-dom";
import useAuth from "hooks/useAuth";
import useResetPasswordPost from "repositories/useResetPasswordPost";
import { BiLoaderCircle } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ResetPassword() {
  // Chakra color mode

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const useResetPassword = useResetPasswordPost();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const { resetPasswordCode } = useParams();
  const handleSubmmit = async e => {
    e.preventDefault();
    let isValid = true;
    const data = {
      password,
      password_confirmation: passwordConfirmation,
      reset_password_code: resetPasswordCode
    };
    if (password.length < 6) {
        isValid = false;
        toast.error('A senha deve ter no mínimo 6 caracteres')
    }
    if (password !== passwordConfirmation) {
        isValid = false;
        toast.error('As senhas devem ser iguais')
    }
    if (isValid) {
        setIsProcessing(true)
        await useResetPassword(data)
            .then(data => {
                setIsProcessing(false);
                toast.success(data.message)
                console.log(data.status)
                history.replace('/login')
            })
            .catch(error => {
                setIsProcessing(false);
                toast.error(error.message)
            });
    }
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <ToastContainer />
        <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w='100%'
            mx={{ base: "auto", lg: "0px" }}
            me='auto'
            h='100%'
            alignItems='start'
            justifyContent='center'
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}
            flexDirection='column'>
            <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
                Recuperar Senha
            </Heading>
            </Box>
            <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            <FormControl>  
                <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Senha<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Min. 6 caracteres'
                    mb='24px'
                    size='lg'
                    type={show ? "text" : "password"}
                    variant='auth'
                    onChange={password => setPassword(password.target.value)}
                    value={password}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                    />
                </InputRightElement>
                </InputGroup>
                <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Confirmar Senha<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Min. 6 caracteres'
                    mb='24px'
                    size='lg'
                    type={show ? "text" : "password"}
                    variant='auth'
                    onChange={passwordConfirmation => setPasswordConfirmation(passwordConfirmation.target.value)}
                    value={passwordConfirmation}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                    />
                </InputRightElement>
                </InputGroup>
                <Flex justifyContent='space-between' align='center' mb='24px'>
                <NavLink to='/login'>
                    <Text
                    color={textColorBrand}
                    fontSize='sm'
                    w='124px'
                    fontWeight='500'>
                    Logar
                    </Text>
                </NavLink>
                </Flex>
                {isProcessing
                ? <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'
                    disabled={true}
                    >
                    <BiLoaderCircle style = {{transform: 'rotate(90deg)' }} />
                    </Button>
                : <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'
                    onClick={handleSubmmit}
                    >
                    Salvar
                    </Button>
                }
            </FormControl>
            </Flex>
        </Flex>
    </DefaultAuth>
  );
}
export default ResetPassword;