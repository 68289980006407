'use client';
import { useEffect, useRef, useState } from 'react';

// material-ui
import { Button, Dialog, useMediaQuery } from '@mui/material';

// third-party
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import ptBrLocale from '@fullcalendar/core/locales/pt';

// redux
import { useDispatch, useSelector } from 'store';
import { getEvents, addEvent, updateEvent, removeEvent } from 'store/slices/calendar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import CalendarStyled from './CalendarStyled';
import Toolbar from './Toolbar';
import AddEventForm from './AddEventForm';

// assets
import AddAlarmTwoToneIcon from '@mui/icons-material/AddAlarmTwoTone';
import ThemeCustomization from "themes";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// ==============================|| APPLICATION CALENDAR ||============================== //

const Calendar = () => {
  
    const dispatch = useDispatch();
    const calendarRef = useRef(null);
    const matchSm = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // fetch events data
    const [events, setEvents] = useState([]);
    const [adicionado, setEvento] = useState(false);
    const calendarState = useSelector((state) => state.calendar);

    useEffect(() => {
        dispatch(getEvents());
    }, []);

    useEffect(() => {
        setEvents(calendarState.events);
    }, [calendarState]);

    const [date, setDate] = useState(new Date());
    const [view, setView] = useState(matchSm ? 'listWeek' : 'dayGridMonth');

    // calendar toolbar events
    const handleDateToday = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.today();
            setDate(calendarApi.getDate());
        }
    };

    const handleViewChange = (newView) => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.changeView(newView);
            setView(newView);
            console.log(newView)
        }
    };

    // set calendar view
    useEffect(() => {
        handleViewChange(matchSm ? 'listWeek' : 'timeGridWeek');
    }, [matchSm]);

    const handleDatePrev = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.prev();
            setDate(calendarApi.getDate());
        }
    };

    const handleDateNext = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.next();
            setDate(calendarApi.getDate());
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRange, setSelectedRange] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);

    // calendar event select/add/edit/delete
    const handleRangeSelect = (arg) => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.unselect();
        }

        setSelectedRange({
            start: arg.start,
            end: arg.end
        });
        setIsModalOpen(true);
    };

    const handleEventSelect = (arg) => {
        console.log(arg);
        console.log(arg.event.id);
        if (arg.event.id) {
            const selectEvent = events.find((_event) => _event.id === arg.event.id);
            console.log(selectEvent)
            
            setSelectedEvent(selectEvent);
        } else {
            setSelectedEvent(null);
        }
        setIsModalOpen(true);
    };

    const handleEventUpdate = async ({ event }) => {
        try {
            const id = toast.loading("Atualizando evento")
            dispatch(
                updateEvent({
                    eventId: event.id,
                    update: {
                        allDay: event.allDay,
                        data_inicio: event.start,
                        data_fim: event.end
                    }
                })
            );
            toast.update(id, { render: "Evento atualizado com sucesso", type: "success", isLoading: false, autoClose: 4000 });
        } catch (err) {
            console.error(err);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedEvent(null);
        setSelectedRange(null);
    };

    const handleEventCreate = async (data) => {
        const id = toast.loading("Adicionando evento")
        await dispatch(addEvent(data));
        toast.update(id, { render: "Evento adicionado com sucesso", type: "success", isLoading: false, autoClose: 4000 });
        handleModalClose();
        dispatch(getEvents());
    };

    const handleUpdateEvent = async (eventId, update) => {
        const id = toast.loading("Atualizando evento")
        await dispatch(updateEvent({ eventId, update }));
        toast.update(id, { render: "Evento atualizado com sucesso", type: "success", isLoading: false, autoClose: 4000 });
        handleModalClose();
        dispatch(getEvents());
    };

    const handleEventDelete = async (id) => {
        try {
            const id = toast.loading("Atualizando evento")
            await dispatch(removeEvent(id));
            toast.update(id, { render: "Evento excluido com sucesso", type: "success", isLoading: false, autoClose: 4000 });
            handleModalClose();
            dispatch(getEvents());
        } catch (err) {
            console.error(err);
        }
    };

    const handleAddClick = () => {
        setIsModalOpen(true);
    };

    return (
        <ThemeCustomization>
          <ToastContainer autoClose={5000} />
          <MainCard
              title="Agenda"
              secondary={
                  <Button color="primary" variant="contained" onClick={handleAddClick}>
                      <AddAlarmTwoToneIcon fontSize="small" sx={{ mr: 0.75 }} />
                      Novo Evento
                  </Button>
              }
          >
              <CalendarStyled>
                  <Toolbar
                      date={date}
                      view={view}
                      onClickNext={handleDateNext}
                      onClickPrev={handleDatePrev}
                      onClickToday={handleDateToday}
                      onChangeView={handleViewChange}
                  />
                  <SubCard>
                      <FullCalendar
                          weekends
                          editable
                          droppable
                          selectable
                          events={events}
                          ref={calendarRef}
                          rerenderDelay={10}
                          initialDate={date}
                          initialView={view}
                          dayMaxEventRows={3}
                          eventDisplay="block"
                          headerToolbar={false}
                          allDayMaintainDuration
                          eventResizableFromStart
                          select={handleRangeSelect}
                          eventDrop={handleEventUpdate}
                          eventClick={handleEventSelect}
                          eventResize={handleEventUpdate}
                          height={matchSm ? 'auto' : 720}
                          plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
                          eventMaxStack={1}
                          locale={ptBrLocale}
                      />
                  </SubCard>
              </CalendarStyled>
              {/* Dialog renders its body even if not open */}
              <Dialog maxWidth="sm" fullWidth onClose={handleModalClose} open={isModalOpen} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
                  {isModalOpen && (
                      <AddEventForm
                          event={selectedEvent}
                          range={selectedRange}
                          onCancel={handleModalClose}
                          handleDelete={handleEventDelete}
                          handleCreate={handleEventCreate}
                          handleUpdate={handleUpdateEvent}
                      />
                  )}
              </Dialog>
          </MainCard>
        </ThemeCustomization>
    );
};

export default Calendar;
