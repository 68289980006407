import {
  Box,
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState, useRef } from "react";
import ModalMoreInfoTable from "./ModalMoreInfoTable";
import ContentAvaliacoes from "./ContentAvaliacoes";
import ContentInformacaoEssenciais from "./ContentInformacaoEssenciais";
import ContentMaturidadeDigital from "./ContentMaturidadeDigital";
import ContentComparacaoConcorrentes from "./ContentComparacaoConcorrentes";
import calcularInformacaoEssenciais from "services/calcularInformacaoEssenciais";
import calcularMaturidadeDigital from "services/calcularMaturidadeDigital";
import { IconError, IconExternal, IconSuccess } from "./icons";
import { SiGooglemybusiness } from "react-icons/si";
import { BiLocationPlus, BiMinusCircle, BiPlusCircle } from "react-icons/bi";
import { DiGoogleAnalytics } from "react-icons/di";
import ContentVisitas from "./ContentVisitas";
import unidadeRespondeAvaliacao from "services/unidadeRespondeAvaliacao";
import ContentRespondeAvaliacao from "./ContentRespondeAvaliacao";
import ContentAnaliseGuiada from "./ContentAnaliseGuiada";
import {
  UnitsContext,
  SelectedLocationContext,
} from "contexts/prospeccao/IndexContext";
import useUnitRepositoryPutUnit from "repositories/useUnitRepositoryPutUnit";
import { GlobalLoadingContext } from "contexts/prospeccao/IndexContext";
import { SharedFunctionContext } from "contexts/prospeccao/IndexContext";

const MIN_PHOTO = 10;
const MIN_REVIEWS = 10;
const MIN_AVG_REVIEWS = 4.0;
const MIN_DIGITAL_MATURITY = 7;

function TableUnitInformation({ unit, refreshState, tableUnitInformationRef }) {
  const [modalState, setModalState] = useState({ id: null, visible: false });
  const [stateSharedFunctions, setStateSharedFunctions] = useContext(
    SharedFunctionContext
  );

 
  let info = calcularInformacaoEssenciais(unit);
  let digitalMaturity = calcularMaturidadeDigital(unit);

  const [unitsContext, setUnitsContext] = useContext(UnitsContext);
  const [selectedLocation, setSelectedLocation] = useState(
    SelectedLocationContext
  );
  const [globalLoading, setGlobalLoading] = useContext(GlobalLoadingContext);
  const unitRepositoryPutUnit = useUnitRepositoryPutUnit();

  const handleCloseModal = () => {
    setModalState({ visible: false, id: null });
  };

  const MODAL_IDS = {
    avaliacao: {
      title: "Detalhes",
      content: () => <ContentAvaliacoes unit={unit} />,
    },
    visita: {
      title: "Visitas",
      content: () => <ContentVisitas unit={unit} />,
    },
    analise_guiada: {
      title: "Analise Guiada",
      content: () => <ContentAnaliseGuiada unit={unit} />,
    },
    horario_funcionamento: {},
    responde_avaliacao: {
      title: "Responde Avaliação",
      content: () => <ContentRespondeAvaliacao unit={unit} />,
    },
    info_essenciais: {
      title: "Informações Essenciais",
      content: () => <ContentInformacaoEssenciais unit={unit} />,
    },
    maturidade_digital: {
      title: "Maturidade Digital",
      content: () => (
        <ContentMaturidadeDigital digitalMaturity={digitalMaturity} />
      ),
    },
    comparacao_concorrentes: {
      title: "Comparação de Concorrentes",
      content: () => <ContentComparacaoConcorrentes unit={unit} />,
    },
  };

  const hasWebsite = () => {
    return !!unit.site;
  };

  const hasVerified = () => {
    return !!unit.verified;
  };

  const hasCompetitors = () => {
    return !!Object.keys(unit.competitors.todos).length;
  };

  const countPhotos = () => {
    return unit.photos_count;
  };

  const countReviews = () => {
    return unit.reviews;
  };

  const hasOrganicPost = () => {
    return !!unit.posts;
  };

  const reviewAvg = () => {
    return unit.rating;
  };

  const hasHours = () => {
    return !!unit.working_hours;
  };

  const handleClickModal = (idModal) => {
    let modal = MODAL_IDS[idModal];
    if (!modal) return;

    setModalState({ visible: true, id: idModal });
  };

  console.log('tableUnitInformationRef', tableUnitInformationRef)

  if (tableUnitInformationRef) {
    tableUnitInformationRef.current = {
      handleClickModal: handleClickModal,
      modalState: modalState,
    }
  }

  console.log('tableUnitInformationRef', tableUnitInformationRef)


  const handleClickDisabledUnit = async (unit) => {
    setGlobalLoading(true);
    setUnitsContext(
      unitsContext.map((elementUnit) => {
        if (elementUnit._id === unit._id) {
          elementUnit.disabled = !elementUnit.disabled;
          unit.disabled = elementUnit.disabled;
        }

        return elementUnit;
      })
    );
    await unitRepositoryPutUnit(unit._id, { disabled: unit.disabled });
    setSelectedLocation(unit);
    setGlobalLoading(false);
  };

  const hasInformacaoEssenciais = () => {
    if (info.possui > info.naopossui) {
      return true;
    }
    return false;
  };

  const isRespondeAvaliacao = () => {
    let infoCalculo = unidadeRespondeAvaliacao(unit);
    if (infoCalculo.nao_respondida > infoCalculo.respondida) return false;
    return true;
  };


  
  

  return (
    <div>
      <div>
        <Box>
          <Button onClick={() => handleClickModal("visita")}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"black.500"}
              as={BiPlusCircle}
            />
          </Button>
          <a target="__blank" href={unit.location_link}>
            <Button title="Link para a ficha do Google Meu Negócio">
              <Icon
                w="24px"
                h="24px"
                me="5px"
                color={"black.500"}
                as={SiGooglemybusiness}
              />
            </Button>
          </a>
          <Button onClick={() => handleClickModal("analise_guiada")}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"black.500"}
              as={DiGoogleAnalytics}
            />
          </Button>
          <Button onClick={() => handleClickDisabledUnit(unit)}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"black.500"}
              as={unit.disabled ? BiLocationPlus : BiMinusCircle}
            />
          </Button>
        </Box>
        {modalState.visible && (
          <ModalMoreInfoTable
            isOpen={modalState.visible}
            onClose={handleCloseModal}
            title={MODAL_IDS[modalState.id].title}
            content={MODAL_IDS[modalState.id].content}
          />
        )}
      </div>

      <div>
        <Box></Box>
      </div>
      <Table className="tableUnitInformation">
        <Thead>
          <Tr>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Ficha verificada?</Td>
            <Td>
              <div>{hasVerified() ? IconSuccess : IconError}</div>
            </Td>
          </Tr>
          <Tr>
            <Td>Possui site?</Td>
            <Td>
              <div>{hasWebsite() ? IconSuccess : IconError}</div>
            </Td>
          </Tr>

          <Tr>
            <Td>Quantidade de Fotos</Td>
            <Td>
              <div>
                {countPhotos() < MIN_PHOTO ? (
                  <Flex alignItems={"center"} alignContent={"center"}>
                    {countPhotos()}
                    {IconError}
                  </Flex>
                ) : (
                  <Flex>
                    {countPhotos()}
                    {IconSuccess}
                  </Flex>
                )}
              </div>
            </Td>
          </Tr>

          <Tr>
            <Td>Informações essenciais</Td>
            <Td>
              <div className="d-flex align-self-center">
                {info.possui + "/" + info.naopossui}

                {hasInformacaoEssenciais() ? IconSuccess : IconError}
                {
                  <>
                    <IconExternal
                      onClick={() => handleClickModal("info_essenciais")}
                    />
                  </>
                }
              </div>
            </Td>
          </Tr>

          <Tr>
            <Td>Maturidade Digital</Td>
            <Td>
              <div>
                {digitalMaturity.sum < MIN_DIGITAL_MATURITY ? (
                  <Flex alignItems={"center"} alignContent={"center"}>
                    {digitalMaturity.sum}
                    {IconError}
                    <IconExternal
                      onClick={() => handleClickModal("maturidade_digital")}
                    />
                  </Flex>
                ) : (
                  <Flex>
                    {digitalMaturity.sum}
                    {IconSuccess}
                    <IconExternal
                      onClick={() => handleClickModal("maturidade_digital")}
                    />
                  </Flex>
                )}
              </div>
            </Td>
          </Tr>

          <Tr>
            <Td>Responde Avaliações</Td>
            <Td>
              <div className="d-flex align-self-center">
                {isRespondeAvaliacao() ? IconSuccess : IconError}
                {
                  <>
                    <IconExternal
                      onClick={() => handleClickModal("responde_avaliacao")}
                    />
                  </>
                }
              </div>
            </Td>
          </Tr>

          <Tr>
            <Td>Quantidade de Avaliações</Td>
            <Td>
              <div>
                {countReviews() < MIN_REVIEWS ? (
                  <Flex alignItems={"center"} alignContent={"center"}>
                    {countReviews()}
                    {IconError}
                    <IconExternal
                      onClick={() => handleClickModal("avaliacao")}
                    />
                  </Flex>
                ) : (
                  <Flex>
                    {countReviews()}
                    {IconSuccess}
                    <IconExternal
                      onClick={() => handleClickModal("avaliacao")}
                    />
                  </Flex>
                )}
              </div>
            </Td>
          </Tr>

          <Tr>
            <Td>Comparação de concorrentes</Td>
            <Td>
              <div className="d-flex align-self-center">
                {hasCompetitors() ? IconSuccess : IconError}
                {hasCompetitors() ? (
                  <IconExternal
                    onClick={() => handleClickModal("comparacao_concorrentes")}
                  />
                ) : null}
              </div>
            </Td>
          </Tr>

          <Tr>
            <Td>Média de Avaliações</Td>
            <Td>
              {reviewAvg() < MIN_AVG_REVIEWS ? (
                <Flex alignItems={"center"} alignContent={"center"}>
                  {reviewAvg()}
                  {IconError}
                </Flex>
              ) : (
                <Flex>
                  {reviewAvg()}
                  {IconSuccess}
                </Flex>
              )}
            </Td>
          </Tr>

          <Tr>
            <Td>Possui conteúdo organico?</Td>
            <Td>
              <div>{hasOrganicPost() ? IconSuccess : IconError}</div>
            </Td>
          </Tr>

          <Tr>
            <Td>Possui horários de Funcionamento?</Td>
            <Td>
              <div>{hasHours() ? IconSuccess : IconError}</div>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
}

export default TableUnitInformation;
