import moment from "moment";
export default function getMinutesVisita(initialTime, endTime = null) {
  let format;
  
  var now = moment(endTime || moment.now()); //todays date
  var end = moment(initialTime); // another date
  var duration = moment.duration(now.diff(end));

  format = getStringFormat(duration.asMilliseconds());

  return moment.utc(duration.asMilliseconds()).format(format);
}

export function calcularTempo(initialTime, endTime = null) {
  var now = moment(endTime || moment.now()); //todays date
  var end = moment(initialTime); // another date
  var duration = moment.duration(now.diff(end));

  return duration.asMilliseconds();
}

function getStringFormat(miliseconds) {
  const oneHourInMiliseconds = 60 * 60000;

  if (miliseconds >= oneHourInMiliseconds) {
    return "kk:mm:ss";
  }
  
  return "mm:ss";
  
}
