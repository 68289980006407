import {
  Badge,
  Box,
  Button,
  Heading,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import { BiMap } from "react-icons/bi";
import { IoMapOutline } from "react-icons/io5";
import { SiMapbox } from "react-icons/si";
import { useHistory } from "react-router-dom";
import RouteItemList from "./RouteItemList";

function RoutesPage({ routes, handleClickNewRotas, handleClickViewAllRotasMap }) {
  let history = useHistory();

  const handleClickRota = (route) => {
    history.push(`/admin/default?rota=${route._id}`);
  };

  return (
    <div>
      <Box
        p="40px"
        color="white"
        mt="4"
        bg="teal.500"
        rounded="md"
        shadow="md"
        mb={5}
        display="flex"
      >
        <Text fontSize="xl" fontWeight="bold">
          Rotas do Sistema
        </Text>

        <Button
          colorScheme={"green"}
          style={{ position: "absolute", right: 100 }}
          onClick={handleClickNewRotas}
        >
          <Text fontSize="xl" fontWeight="bold">
            Criar Rota
          </Text>
        </Button>

        <Button
          colorScheme={"purple"}
          style={{ position: "absolute", right: 300 }}
          onClick={handleClickViewAllRotasMap}
        >
          <Text fontSize="xl" fontWeight="bold">
            Mapa de Rotas
          </Text>
        </Button>
      </Box>
      {routes.map((route, index) => {
        return (
          <RouteItemList route={route} key={index} goToShowRoute={true}/>
        );
      })}
    </div>
  );
}

export default RoutesPage;
