import { Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";

import useVisitRepositoryGetVisits from "repositories/useVisitRepositoryGetVisit";
import useVisitRepositoryPostVisit from "repositories/useVisitRepositoryPostVisit";
import useVisitRepositoryPutVisit from "repositories/useVisitRepositoryPutVisit";
import useGuidedAnalysisRepositoryPostGuidedAnalysis from "repositories/useGuidedAnalysisPostAnalysis";
import useGuidedAnalysisGetAnalysis from "repositories/useGuidedAnalysisGetAnalysis";

import {UnitsContext, SelectedLocationContext, OpenVisitContext} from "contexts/prospeccao/IndexContext";

import moment from "moment";
import NovaVisita from "./NovaVisita";
import VisitaEmCurso from "./VisitaEmCurso";
import AnaliseGuiada from "./AnaliseGuiada";

function getCurrentVisit(visits) {
  return visits.find((el) => el.data_inicio_visita && !el.data_fim_visita);
}

const TYPE_CURRENT_VISIT = "TYPE_CURRENT_VISIT";
const TYPE_NEW_VISIT = "TYPE_NEW_VISIT";

function ContentVisitas({ unit }) {
  const [openVisit, setOpenVisit] = useContext(OpenVisitContext);
  const [unitsContext, setUnitsContext] = useContext(UnitsContext);
  const [selectedLocationContext, setSelectedLocationContext] = useContext(SelectedLocationContext);
  
  const [visits, setVisits] = useState([]);
  const [guidedAnalys, setGuidedAnalysis] = useState([]);

  const [type, setType] = useState(TYPE_NEW_VISIT);
  const [loading, setLoading] = useState(true);
  const [openedVisit, setOpenedVisit] = useState({});

  const fetchVisits = useVisitRepositoryGetVisits();
  const updateVisit = useVisitRepositoryPutVisit();
  const createGuidedAnalysis = useGuidedAnalysisRepositoryPostGuidedAnalysis();
  const fetchGuidedAnalysis = useGuidedAnalysisGetAnalysis();

  useEffect(async () => {
    await fetchVisitaAndStoreState();
    
  }, []);

  const fetchVisitaAndStoreState = async () => {
    setLoading(true);
    const visits = await fetchVisits(unit._id);
    const guidedAnalys = await fetchGuidedAnalysis(unit._id);
    setVisits(visits);
    setGuidedAnalysis(guidedAnalys);
    let visit = getCurrentVisit(visits);
    if (!visit) setType(TYPE_NEW_VISIT);
    else setType(TYPE_CURRENT_VISIT);

    setLoading(false);
  };

  const createVisit = useVisitRepositoryPostVisit();

  const handleSalvarAnaliseGuiada = async ({ formState, visita }) => {
    setLoading(true);
    await createGuidedAnalysis(unit._id, {
      ...formState,
      usuario_id: 1,
    });
    await fetchVisitaAndStoreState();
  };

  const handleClickNovaVisita = async () => {
    setLoading(true);

    const response = await createVisit(unit._id, {
      data_inicio_visita: moment(moment.now()).utc(),
      usuario_id: 1,
    });

    if ("error" in response) {
      handleErrorOpenedVisits(response);
      return;
    }

    updateVisitInUnitOnMap(response);
    updateVisitInUnitOnCardInformation(response);
    showVisitTimerOnIndex(response);

    await fetchVisitaAndStoreState();

  };

  const updateVisitInUnitOnMap = (visitCreated) => {
    setUnitsContext(unitsContext.map((elementUnit) => {
      if (elementUnit._id == unit._id) {
        elementUnit.visit.push(visitCreated);
      }

      return elementUnit;
    }));
  }

  const updateVisitInUnitOnCardInformation = (visitCreated) => {
    let visit = unit.visit;
    visit.push(visitCreated);

    setSelectedLocationContext({
      ...unit,
      visit:visit
    });
  }

  const showVisitTimerOnIndex = (visitCreated) => {
    setOpenVisit(visitCreated);
  }

  const handleErrorOpenedVisits = (response) => {
    setLoading(false);

    if (!('message' in response)) {
      return;
    }

    if (response['message'] != 'Usuário possui visita em aberto') {
      return;
    }
    
    setOpenedVisit(response['data']);
  };

  const handleFinalizarVisita = async ({ formState, visita }) => {
    setLoading(true);
    setOpenVisit({});
    await updateVisit(visita._id, {
      ...formState,
      data_fim_visita: moment(moment.now()).utc(),
    });
    await fetchVisitaAndStoreState();
  };

  ;

  if (loading) {
    return (
      <Flex>
        <Spinner
          fontSize={48}
          size={"xl"}
          margin={"auto"}
          height="100px"
          width={"100px"}
        />
      </Flex>
    );
  }

  if (guidedAnalys.length === 0) {
    return (
      <AnaliseGuiada
        visita={getCurrentVisit(visits)}
        handleSalvarAnaliseGuiada={handleSalvarAnaliseGuiada}
      />
    );
  }

  if (type === TYPE_CURRENT_VISIT) {
    return (
      <VisitaEmCurso
        visita={getCurrentVisit(visits)}
        handleFinalizarVisita={handleFinalizarVisita}
      />
    );
  }

  if (type === TYPE_NEW_VISIT) {
    return (
      <NovaVisita
        visits={visits}
        handleClickNovaVisita={handleClickNovaVisita}
      />
    );
  }

  return <div>Ocorreu um erro...</div>;
}

export default ContentVisitas;
