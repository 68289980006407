import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import BoxStars from "./BoxStars";

function ContentAvaliacoes({ unit }) {
  return (
    <div>
      <Flex>
        <Text mx={"auto"} fontWeight={"bold"} fontSize={"16"}>
          Avaliações por Estrela
        </Text>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>Estrelas</Th>
            <Th>Avaliações</Th>
          </Tr>
        </Thead>

        <Tbody>
          <Tr>
            <Td>
              <BoxStars countStars={5} />
            </Td>
            <Td>{unit.reviews_per_score["5"] || 0}</Td>
          </Tr>
          <Tr>
            <Td>
              <BoxStars countStars={4} />
            </Td>
            <Td>{unit.reviews_per_score["4"] || 0}</Td>
          </Tr>
          <Tr>
            <Td>
              <BoxStars countStars={3} />
            </Td>
            <Td>{unit.reviews_per_score["3"] || 0}</Td>
          </Tr>
          <Tr>
            <Td>
              <BoxStars countStars={2} />
            </Td>
            <Td>{unit.reviews_per_score["2"] || 0}</Td>
          </Tr>
          <Tr>
            <Td>
              <BoxStars countStars={1} />
            </Td>
            <Td>{unit.reviews_per_score["1"] || 0}</Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
}

export default ContentAvaliacoes;
