import { Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { IoStar } from "react-icons/io5";

function BoxStars({ countStars }) {
  let number = parseInt(countStars);
  if (!number) return <div></div>;

  let rendersStars = new Array(number)
    .fill(1)
    .map((_, index) => (
      <Icon key={index} as={IoStar} w="24px" h="24px" color={"yellow.400"} />
    ));

  return (
    <div >
      <Tooltip label={countStars}>
        <div style={{display: 'flex'}}>{rendersStars}</div>
      </Tooltip>
    </div>
  );
}

export default BoxStars;
