import { useState, useEffect } from "react";
import { BASE_URL } from "services/baseurl";

const useVisitRepositoryPutVisit = () => {
  const fetchPutVisit = async (visitId, payload) => {
    const url = `${BASE_URL}visit?visit_id=${visitId}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    return responseJson;
  };

  return fetchPutVisit;
};

export default useVisitRepositoryPutVisit;
