import moment from "moment";
import getMinutesVisita, { calcularTempo } from "./calcularDuracaoVisita";

export default function (unit) {
  if (!unit.reviews_data)
    return {
      total_avaliacao: unit.reviews,
      respondida: 0,
      nao_respondida: 0,
      reviews: [],
    };

  let respondida = 0,
    naoRespondida = 0,
    tempoResposta = 0;

  unit.reviews_data.forEach((review) => {
    if (review.owner_answer) {
      respondida++;
    } else {
      naoRespondida++;
    }
    if (review.owner_answer_timestamp_datetime_utc) {
      let count = calcularTempo(
        review.review_datetime_utc,
        review.owner_answer_timestamp_datetime_utc
      );
      tempoResposta += count;
    }
  });

  let tempoMedioResposta = "N/A";

  if (respondida) {
    tempoMedioResposta = moment.utc(tempoResposta / respondida).format("mm:ss");
  }

  return {
    total_avaliacao: unit.reviews,
    tempo_medio_resposta: tempoMedioResposta,
    respondida: respondida,
    nao_respondida: naoRespondida,
    reviews: unit.reviews_data,
  };
}
