import { useState, useEffect } from "react";
import { BASE_URL } from "services/baseurl";

const useUnitRepositoryGetUnits = () => {
  return async (codigoCliente) => {
    const url = `${BASE_URL}units?codigo_cliente=${codigoCliente}`;
    const response = await fetch(url);
    const responseJson = await response.json();
    return responseJson;
  };
};

const useUnitRepositoryGetUnitsByLatLong = () => {
  return async (codigoCliente, latitude, longitude) => {
    const url = `${BASE_URL}units?codigo_cliente=${codigoCliente}&location=${latitude},${longitude}`;
    const response = await fetch(url);
    const responseJson = await response.json();
    return responseJson;
  };
};

const useUnitRepositoryFindUnitsByPaths = () => {

  return async (codigoCliente, paths) => {
    const url = `${BASE_URL}units/find_by_paths?codigo_cliente=${codigoCliente}`;
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        {
          'paths':  paths
        }
      ),
    });
    const responseJson = await response.json();
    return responseJson;
  };
}

const useUnitRepositoryFindUnitsStreets = () => {

  return async (codigoCliente, lat, lng) => {
    const url = `${BASE_URL}routes/calculatePoints?codigo_cliente=${codigoCliente}&lat=${lat}&lon=${lng}`;
    const response = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const responseJson = await response.json();
    return responseJson;
  };
}

const useUnitRepositoryByPolygon = () => {
  return async (codigoCliente, polygon) => {
    const url = `${BASE_URL}units/find_by_polygon?codigo_cliente=${codigoCliente}`;
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        {
          'polygon':  polygon
        }
      ),
    });
    const responseJson = await response.json();
    return responseJson;
  };
}


export {
  useUnitRepositoryGetUnits,
  useUnitRepositoryGetUnitsByLatLong,
  useUnitRepositoryFindUnitsByPaths,
  useUnitRepositoryFindUnitsStreets,
  useUnitRepositoryByPolygon
};
