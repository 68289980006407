import CardLoading from "components/prospeccao/CardLoading";
import CardUnitInformation from "components/prospeccao/CardUnitInformation";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import useUnitRepositoryGetUnit from "repositories/useUnitRepositoryGetUnit";
import { useUnitRepositoryGetUnitsByLatLong } from "repositories/useUnitRepositoryGetUnits";
import useCategoryRepositoryGetCategories from "repositories/useCategoryRepositoryGetCategories";
import CardTimeCurrentVisit from "components/prospeccao/CardTimeCurrentVisit";
import ModalMoreInfoTable from "components/prospeccao/ModalMoreInfoTable";
import ContentOpenedVisit from "components/prospeccao/ContentOpenedVisit";
import IndexContext from "contexts/prospeccao/IndexContext";
import { useRouteRepositoryGetRoutes } from "repositories/useRouteRepositoryRoutes";
import ContainerProspeccao from "components/prospeccao/GoogleMaps/ContainerProspeccao";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

function Prospeccao() {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingUnit, setLoadingUnit] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openVisit, setOpenVisit] = useState({});
  const [modalState, setModalState] = useState({ id: null, visible: false });
  const [routes, setRoutes] = useState([]);

  const fetchUnits = useUnitRepositoryGetUnitsByLatLong();
  const fetchUnit = useUnitRepositoryGetUnit();
  const fetchCategories = useCategoryRepositoryGetCategories();
  const getRoutes = useRouteRepositoryGetRoutes();
  const cardUnitRef = useRef(null);
  const [map, setMap] = useState(null);
  const [pointRoute, setPointRoute] = useState([]);
  const [centerLocation, setCenterLocation] = useState(null);
  const [routeData, setRouteData] = useState(null);
  const [unitsRoute, setUnitsRoute] = useState([]);
  const [stateSharedFunctions, setStateSharedFunctions] = useState({});

  const tableUnitInformationRef = useRef(null);

  const MODAL_IDS = {
    visita_em_andamento: {
      title: "Visita em andamento",
      content: () => <ContentOpenedVisit />,
    },
  };

  useEffect(async () => {
    const routesPromise = getRoutes(4);
    const categoriesPromise = fetchCategories();

    const [routes, categories] = await Promise.all([
      routesPromise,
      categoriesPromise,
    ]);

    setUnits(units);
    setCategories(categories);
    setRoutes(routes);
    setLoadingPage(false);
  }, []);

  useEffect(async () => {
    if (Object.keys(openVisit).length) {
      handleClickLocation(openVisit.unit_id, true);
    }
  }, [openVisit]);

  const handleClickLocation = async (locationId, firstLoad) => {
    if (hasVisitInProgress() && !firstLoad) {
      openAlertModalVisitInProgress();
      return;
    }

    setLoadingUnit(true);

    let unit = await fetchUnit(locationId);

    setSelectedLocation(unit);
    setLoadingUnit(false);
  };

  const hasVisitInProgress = () => {
    if (Object.keys(openVisit).length) {
      return true;
    }

    return false;
  };

  const hasLocationSelected = () => {
    return !!selectedLocation;
  };

  if (loadingPage) {
    return <CardLoading box={{ pt: { base: "100px" } }} />;
  }

  const openAlertModalVisitInProgress = () => {
    setModalState({ visible: true, id: "visita_em_andamento" });
  };

  const handleCloseModal = () => {
    setModalState({ visible: false, id: null });
  };

  const ModalInformations = () => {
    return (
      <>
        <div ref={cardUnitRef} style={{}}>
          <Modal
            blockScrollOnMount={false}
            isOpen={true}
            onClose={() => {
              setSelectedLocation(null);
            }}
          >
            <ModalOverlay />
            <ModalContent
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                minHeight: "120vh",
              }}
            >
              <ModalHeader style={{
                heigth: "150px",
              }} >
                {routeData?.name? routeData.name : "Carregando ..." }
              <ModalCloseButton disabled={hasVisitInProgress()} />

              </ModalHeader>
              <ModalBody style={{
                padding: "2px",
              }}>
                {loadingUnit ? (
                  <CardLoading box={{ pt: { base: "20px" } }} />
                ) : (
                  <div>
                    {hasLocationSelected() ? (
                      <>
                        <CardUnitInformation
                          tableUnitInformationRef={tableUnitInformationRef}
                          unit={selectedLocation}
                        />
                      </>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </div>
      </>
    );
  };

  const isModalOpen = () => {
    return hasLocationSelected() || loadingUnit;
  };

  console.log('routeData', routeData)
  console.log('pointRoute', pointRoute)
  console.log('unitsRoute', unitsRoute)
  return (
    <>
      <IndexContext
        stateUnits={[units, setUnits]}
        stateSelectedLocation={[selectedLocation, setSelectedLocation]}
        handleClickLocation={handleClickLocation}
        categories={categories}
        stateOpenVisit={[openVisit, setOpenVisit]}
        stateGlobalLoading={[globalLoading, setGlobalLoading]}
        stateMap={[map, setMap]}
        stateRoute={[routes, setRoutes]}
        statePointsRoute={[pointRoute, setPointRoute]}
        stateCenterLocation={[centerLocation, setCenterLocation]}
        stateRouteData={[routeData, setRouteData]}
        stateUnitsRoute={[unitsRoute, setUnitsRoute]}
        stateSharedFunctions={[stateSharedFunctions, setStateSharedFunctions]}
      >
        {!isModalOpen() && (
          <ContainerProspeccao
            loadingUnit
            unitsLocation={units || []}
            handleClickLocation={handleClickLocation}
          />
        )}

        {isModalOpen() && <ModalInformations />}

        <CardTimeCurrentVisit
          tableUnitInformationRef={tableUnitInformationRef}
        />

        {modalState.visible && (
          <ModalMoreInfoTable
            isOpen={modalState.visible}
            onClose={handleCloseModal}
            title={MODAL_IDS[modalState.id].title}
            content={MODAL_IDS[modalState.id].content}
          />
        )}
      </IndexContext>
    </>
  );
}

export default Prospeccao;
