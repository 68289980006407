import React, { useContext, useEffect, useRef, useState } from "react";

import { GOOGLE_MAPS_API_KEY } from "variables/googleMaps";
import MeLocationMarker from "./MeLocationMarker";
import UnitLocationMarker from "./UnitLocationMarker";
import {
  useUnitRepositoryGetUnitsByLatLong,
  useUnitRepositoryByPolygon,
} from "repositories/useUnitRepositoryGetUnits";
import {
  UnitsContext,
  GlobalLoadingContext,
} from "contexts/prospeccao/IndexContext";
import { Button, color, Flex, Icon, Spinner, Text } from "@chakra-ui/react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  OverlayView,
  DrawingManager,
  Polygon,
} from "@react-google-maps/api";
import { findNearestPoints } from "../rotas/haversine";
import { useUnitRepositoryFindUnitsByPaths } from "repositories/useUnitRepositoryGetUnits";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MapContext } from "contexts/prospeccao/IndexContext";
import { useUnitRepositoryFindUnitsStreets } from "repositories/useUnitRepositoryGetUnits";
import { PointsRouteContext } from "contexts/prospeccao/IndexContext";
import { RouteContext } from "contexts/prospeccao/IndexContext";
import { CenterLocationContext } from "contexts/prospeccao/IndexContext";
import { RouteDataContext } from "contexts/prospeccao/IndexContext";
import calcularTempoRota from "services/calcularTempoRota";
import convertNumberToLetter from "services/numberToLetter";
import { UnitsRouteContext } from "contexts/prospeccao/IndexContext";

const GoogleMaps = ({
  locationUser,
  centerMapProps,
  units,
  handleClickLocation,
  handleChangeCalculateRoute,
  handleAddPolygonInMaps = (polygon) => {
    console.log("polygon added in maps");
  },
  googleMapsRef,
  markerCenter = false,
  route = null,
  isProspeccao = false,
}) => {
  // eslint-disable-next-line no-undef
  const GOOGLE = google;

  const [unitsContext, setUnitsContext] = useContext(UnitsContext);
  const [isLoadingMaps, setIsLoadingMaps] = useContext(GlobalLoadingContext);
  const [map, setMap] = useContext(/** @type google.maps.Map */ (MapContext));
  const [centerOverlay, setCenterOverlay] = useState(
    /** @type google.maps.OverlayView */ (null)
  );
  const [centerMap, setCenterMap] = useState(centerMapProps);
  const directionsRendererRef = useRef(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [onDragged, setOnDragged] = useState(false);
  const [locationPoints, setLocationPoints] = useState([]);
  const [stepPoint, setStepPoint] = useState([]);
  const [routePoints, setRoutePoints] = useContext(PointsRouteContext);
  const [routeData, setRouteData] = useContext(RouteDataContext);
  const [centerLocation, setCenterLocation] = useContext(CenterLocationContext);
  const [drawingManager, setDrawingManager] = useState(null);
  const [polygon, setPolygon] = useState([]);
  const [unitsRoute, setUnitsRoute] = useContext(UnitsRouteContext);
  const [mapUnitsSelected, setMapUnitsSelected] = useState({});

  const mapStyles = [
    {
      featureType: "all",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["geometry", "places", "visualization", "drawing"],
  });

  useEffect(async () => {
    if (centerLocation) return;
    if (isProspeccao) return;
    if (route) return;

    let userLocation = {
      lat: locationUser.latitude,
      lng: locationUser.longitude,
    };
    setCenterMap(userLocation);
    await handleChangeGoogleMaps(userLocation);
  }, []);

  useEffect(async () => {
    if (!centerLocation) return;
    if (isProspeccao) return;

    const location = { lat: centerLocation.lat, lng: centerLocation.lng };
    setCenterMap(location);
  }, [centerLocation]);

  useEffect(() => {
    if (centerLocation) return;
    if (isProspeccao) return;
    if (route) return;

    let centerMap = {
      lat: locationUser.latitude,
      lng: locationUser.longitude,
    };
    setCenterMap(centerMap);
  }, [locationUser]);

  useEffect(() => {
    let mapUnitsSelected = {};
    unitsRoute.forEach((unit) => {
      mapUnitsSelected[unit._id] = unit;
    });

    setMapUnitsSelected(mapUnitsSelected);
  }, [unitsRoute]);

  const fetchUnitsByPolygon = useUnitRepositoryByPolygon();
  const fetchUnits = useUnitRepositoryGetUnitsByLatLong();
  const findStreet = useUnitRepositoryFindUnitsStreets();

  async function handleChangeGoogleMaps({ lat, lng }) {
    if (isLoadingMaps) return;
    setIsLoadingMaps(true);
    const units = await fetchUnits(4, lat, lng);
    let newUnits = Array.from(
      new Set([...(unitsContext || []), ...units])
    ).slice(unitsContext.length, unitsContext.length + 500);
    setIsLoadingMaps(false);
    setUnitsContext(newUnits);
  }

  if (googleMapsRef && googleMapsRef.current)
    googleMapsRef.current.setIsLoadingMaps = setIsLoadingMaps;

  if (googleMapsRef && googleMapsRef.current)
    googleMapsRef.current.setPolygon = setPolygon;

  async function passRoute(route) {
    const { paths, center } = route;

    setIsLoadingMaps(true);

    paths[paths.length] = paths[0];
    setRoutePoints(paths);
    setPolygon([new GOOGLE.maps.Polygon({ paths })]);
    setCenterMap(center);


    const unitsByClient = await fetchUnitsByPolygon(4, paths);

    let mapUnit = {};
    unitsByClient.forEach((unit) => {
      mapUnit[unit._id] = unit;
    });

    const filteredUnits = route.units.map((unit, idx) => {
      let newUnit = mapUnit[unit];
      newUnit.order = idx + 1;
      newUnit.viewRoute = true;
      return newUnit;
    });

    const { units } = calcularTempoRota(filteredUnits, {
      ignoreRoutes: true,
    });


    setUnitsContext(units);
    setUnitsRoute(units);
    setRouteData(route);
    setMapUnitsSelected(mapUnit);

    setIsLoadingMaps(false);
  }
  if (googleMapsRef && googleMapsRef.current)
    googleMapsRef.current.passRoute = passRoute;

  const renderGlobalLoading = () => {
    return (
      isLoadingMaps && (
        <Spinner
          fontSize={5}
          size={"xl"}
          margin={"auto"}
          height="15px"
          width={"15px"}
          style={{ position: "absolute", top: "50%", left: "50%", zIndex: 99 }}
        />
      )
    );
  };

  const RenderLocationMarker = () => {
    return (
      <div>
        {units.map((unit, index) => (
          <OverlayView
            key={index}
            position={{ lat: unit.latitude, lng: unit.longitude }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div>
              <UnitLocationMarker
                key={index}
                id={index}
                location={unit}
                handleClickLocation={handleClickLocation}
                lat={unit.latitude}
                lng={unit.longitude}
                ignoreRoute={!isProspeccao}
                mapUnitsSelected={mapUnitsSelected}
              />
            </div>
          </OverlayView>
        ))}
      </div>
    );
  };

  const RenderUserLocationMarker = ({ locationUser }) => {
    return (
      <div>
        <OverlayView
          position={{ lat: locationUser.latitude, lng: locationUser.longitude }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <MeLocationMarker />
        </OverlayView>
      </div>
    );
  };

  const RenderCenterLocation = ({ center }) => {
    if (center === null) return <div></div>;
    if (!markerCenter) return <div></div>;

    return (
      <div>
        <OverlayView
          position={center}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          onLoad={(overlayView) => {}}
        >
          <FaMapMarkerAlt
            style={{
              color: "var(--chakra-colors-red-500)",
              fontSize: 30,
              cursor: "pointer",
              marginTop: -20,
            }}
          />
        </OverlayView>
      </div>
    );
  };

  const RenderLocationPoints = ({ points }) => {
    return (
      <div>
        {points.map((point, index) => (
          <OverlayView
            key={index}
            position={{ lat: point.lat, lng: point.lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div
              style={{
                width: 25,
                height: 25,
                backgroundColor: "var(--chakra-colors-purple-500)",
                borderRadius: 25,
                transform: "translate(-50%, -50%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <strong style={{ color: "white" }}>
                {convertNumberToLetter(index)}
              </strong>
            </div>
          </OverlayView>
        ))}
      </div>
    );
  };

  const getDirectionResponse = () => {
    return directionsResponse;
  };
  if (googleMapsRef && googleMapsRef.current)
    googleMapsRef.current.getDirectionResponse = getDirectionResponse;

  const getCenterMap = () => {
    if (!map) return null;
    if (!map.center) return null;
    return {
      lat: map.center.lat(),
      lng: map.center.lng(),
    };
  };

  const RenderRouteDirection = () => {
    if (!directionsResponse) return <div></div>;

    return (
      <div>
        <DirectionsRenderer
          options={{ preserveViewport: true, suppressMarkers: true }}
          directions={directionsResponse}
        />
      </div>
    );
  };

  let centerPoint = { lat: -23.533773, lng: -46.62529 };

  if (centerMap){
    centerPoint = centerMap;
  }


  console.log("centerPoint", centerPoint, centerMap);
  return (
    <div style={{ height: "70vh", width: "100%" }}>
      {renderGlobalLoading()}
      <GoogleMap
        ref={googleMapsRef}
        center={centerMap}
        zoom={20}
        onCenterChanged={() => {
          let center = getCenterMap();
          if (!center) return;

          centerPoint.lat = center.lat;
          centerPoint.lng = center.lng;
        }}
        onDragEnd={async () => {
          setCenterMap(getCenterMap());
          console.log(route);
          if (route || isProspeccao || polygon.length > 0) return;
          await handleChangeGoogleMaps(getCenterMap());
        }}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        options={{
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          styles: mapStyles,
        }}
        onLoad={(map) => {
          setMap(map);
          const directionsRenderer = new GOOGLE.maps.DirectionsRenderer({
            map,
          });
          directionsRendererRef.current = directionsRenderer;
        }}
      >
        {(!isProspeccao && route===null) && (
          <DrawingManager
            options={{
              drawingMode: null,
              drawingControl: true,
              drawingControlOptions: {
                position: GOOGLE.maps.ControlPosition.TOP_CENTER,
                drawingModes: [GOOGLE.maps.drawing.OverlayType.POLYGON],
              },
              polygonOptions: {
                fillColor: `#ffff00`,
                fillOpacity: 0.0,
                strokeWeight: 5,
              },
            }}
            onLoad={(drawingManager) => {
              console.log(drawingManager);
              setDrawingManager(drawingManager);
            }}
            onPolygonComplete={(polygonCompleted) => {
              if (polygon.length > 0) {
                polygon[0].setMap(null);
              }
              polygonCompleted.setMap(null);
              setPolygon([polygonCompleted]);

              handleAddPolygonInMaps(polygonCompleted);
            }}
          />
        )}
        <RenderLocationMarker />
        <RenderUserLocationMarker locationUser={locationUser} />
        <RenderRouteDirection />
        <RenderLocationPoints points={routePoints} />

        {polygon.length > 0 && (
          <Polygon
            onLoad={() => {
              console.log("polygon loaded");
            }}
            paths={polygon[0].getPath().getArray()}
            options={{
              fillOpacity: 0.2,
              strokeWeight: 5,
              clickable: false,
              editable: false,
              zIndex: 1,
            }}
            onRightClick={() => {
              console.log("polygon right click");
            }}
          />
        )}

        <RenderCenterLocation center={centerPoint} />
      </GoogleMap>
    </div>
  );
};

export default GoogleMaps;
