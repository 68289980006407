import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import unidadeRespondeAvaliacao from "services/unidadeRespondeAvaliacao";
import { IconError, IconSuccess } from "./icons";

function ContentRespondeAvaliacao({ unit }) {
  let infoCalculo = unidadeRespondeAvaliacao(unit);
  ;

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Total de Avalições</Td>
            <Td>
              <Text fontSize={18} fontWeight="bold">
                {infoCalculo.total_avaliacao}
              </Text>
            </Td>
          </Tr>

          <Tr>
            <Td>Avalições Respondida</Td>
            <Td>
              <Text fontSize={18} fontWeight="bold">
                {infoCalculo.respondida}
              </Text>
            </Td>
          </Tr>

          <Tr>
            <Td>Avaliações Não Respondida</Td>
            <Td>
              <Text fontSize={18} fontWeight="bold">
                {infoCalculo.nao_respondida}
              </Text>
            </Td>
          </Tr>
          <Tr>
            <Td>Tempo Médio de Resposta</Td>
            <Text fontSize={18} fontWeight="bold">
              {infoCalculo.tempo_medio_resposta}
            </Text>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
}

export default ContentRespondeAvaliacao;
