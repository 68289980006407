import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import {
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { Provider } from "react-redux";
import { store } from "store";
import ProvideAuth from "contexts/ProvideAuth";
import SignIn from "views/auth/signIn";
import ResetPassword from "views/auth/resetpassword/ResetPassword";
import ForgotPassword from "views/auth/forgotpassword";
import GuestGuard from "utils/route-guard/GuestGuard";

import { authHandler } from "utils/auth";

authHandler();


ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <ThemeEditorProvider>
        <ProvideAuth>
          <Router>
            <Switch>
              <GuestGuard path="/login" component={SignIn} />
              <GuestGuard path="/forgot-password" component={ForgotPassword} />
              <GuestGuard path="/reset-password/:resetPasswordCode" component={ResetPassword} />
            </Switch>
            <Route path="/admin" component={AdminLayout} />
          </Router>
        </ProvideAuth>
      </ThemeEditorProvider>
    </ChakraProvider>
  </Provider>,
  document.getElementById("root")
);
