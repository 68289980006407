import React, { useContext } from "react";
import ItemLocationCard from "./ItemLocationCard";
import { GlobalLoadingContext } from "contexts/prospeccao/IndexContext";
import CardLoading from "./CardLoading";
import { RouteDataContext } from "contexts/prospeccao/IndexContext";

function ListUnitRoute({ unitsLocation, handleClickLocation, location }) {
  const [globalLoading, setGlobalLoading] = useContext(GlobalLoadingContext);
  const [routeData, setRouteData] = useContext(RouteDataContext);



  if (globalLoading) {
    return <CardLoading box={{ pt: { base: "100px" } }} />;
  }

  console.log('rrroute data', routeData)
  return (
    <div>
      {unitsLocation.map((location, index) => (
        <ItemLocationCard
          key={index}
          route={routeData}
          location={location}
          clickLocation={handleClickLocation}
        />
      ))}
    </div>
  );
}

export default ListUnitRoute;
