import {
  Button,
  Flex,
  Image,
  Text,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/logoWhite.png";
import { MdLogout } from "react-icons/md";
import React, { useState } from "react";
import useAuth from "hooks/useAuth";
import { useHistory } from "react-router-dom";

export default function SidebarDocs() {
  const auth = useAuth();
  const history = useHistory();
  const [bgColour, setBgColour] = useState("#805AD5");
  const appStyles={
    background:`${bgColour}`
  };

  const loggout = () => {
    auth.signout()
    history.replace('/login')
  }

  return (
    <Flex
      justify="center"
      align="center"
      gap={'8px'}
      
    >
      <Button
        style={appStyles}
        textColor={'white'}
        onMouseOver={() => setBgColour('#422AFB')}
        onMouseLeave={() => {setBgColour('#805AD5');}}
        onClick={loggout}
      >
        <Text mr={'4px'}>
          Log out</Text> <Icon as={MdLogout} />
      </Button>
    </Flex>
  );
}
