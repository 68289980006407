import {
  Box,
  Collapse,
  Flex,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { GlobalLoadingContext } from "contexts/prospeccao/IndexContext";
import React, { useContext } from "react";
import { SiGooglemybusiness } from "react-icons/si";

import { FaPeopleArrows, FaStreetView, FaWalking } from "react-icons/fa";
import { RouteDataContext } from "contexts/prospeccao/IndexContext";
import calcularTempoRota from "services/calcularTempoRota";
import convertNumberToLetter from "services/numberToLetter";

function CardTableRotasInformacoes({ units, routePoints }) {
  const { isOpen, onToggle } = useDisclosure();
  const [globalLoading, setGlobalLoading] = useContext(GlobalLoadingContext);

  console.log(routePoints)

  let durationLocomotion = 0;
  let durationProspect = 0;
  let duration = 0;
  let distanceKm = 0;

  if (units && units.length> 0) {
    let tempoRota = calcularTempoRota(units);

    durationLocomotion = tempoRota.timeDistance;
    durationProspect = tempoRota.timeNegociacao;

    duration = Number(tempoRota.timeTotal).toFixed(0);
    distanceKm = tempoRota.totalDistance.toFixed(1);

  console.log(tempoRota)

  }

  const options = {
    title: `Essa rota possui o tempo estimado de ${duration} minutos.`,
    text: "Clique aqui para ver detalhes da sua rota",
    bgColor: "teal.400",
  };


  if (units.length == 0) {
    options.bgColor = "red.500";
    options.title = "Não há unidades disponíveis na rota ";
    options.text =
      "Posicione o ponto vermelho no mapa para o ponto de partida da rota.";
  }

  if (globalLoading) {
    options.bgColor = "gray.500";
    options.title = "Carregando...";
    options.text =
      "Aguarde enquanto carregamos as unidades disponíveis para essa rota.";
  }

  return (
    <div>
      <Box
        p="40px"
        color="white"
        mt="4"
        bg={options.bgColor}
        rounded="md"
        shadow="md"
        onClick={onToggle}
        style={{ cursor: "pointer" }}
      >
        <Text fontSize="xl" fontWeight="bold">
          {options.title}
        </Text>
        <p>{options.text}</p>
      </Box>
      {routePoints && routePoints.length>0 && (
        <Collapse in={isOpen} animateOpacity>
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={3}
            mt={3}
          >
            <Box p="20px" rounded={{ sm: "md" }} overflow="hidden">
              <Flex justifyContent={"space-between"}>
                <Text>
                  <Icon w="24px" h="24px" color={"black.500"} as={FaWalking} />
                  Tempo de caminhada: {durationLocomotion.toFixed(1)} minutos
                  <br />
                </Text>
                <Text>
                  <Icon
                    w="24px"
                    h="24px"
                    color={"black.500"}
                    as={FaStreetView}
                  />
                  Distancia: {distanceKm} km
                  <br />
                </Text>
                <Text>
                  <Icon
                    w="24px"
                    h="24px"
                    color={"black.500"}
                    as={SiGooglemybusiness}
                  />
                  Total de Estabelecimentos: {units.length}
                </Text>

                <Text>
                  <Icon
                    w="24px"
                    h="24px"
                    color={"black.500"}
                    as={FaPeopleArrows}
                  />
                  Tempo de Negociação: {durationProspect} minutos
                </Text>
              </Flex>
            </Box>

            <Box p="20px" rounded={{ sm: "md" }} overflow="hidden">
              <Flex justifyContent={"space-between"}>
                <Text>
                  <b>Ponto de Inicio: {routePoints[0].name} </b>
                  
                  <br />
                </Text>
                <Text>
                  <b>Ponto de Fim: {routePoints[routePoints.length-1].name} </b>
                  
                  <br />
                </Text>
              </Flex>
            </Box>

            <Box>
              {routePoints.map((step, index) => {
                return (
                  <Box
                    pl="30px"
                    pr="30px"
                    pt="20px"
                    pb="20px"
                    color="white"
                    mt="4"
                    bg={"gray.500"}
                    rounded="md"
                    shadow="md"
                    key={index}
                  >
                    <Box>
                      <Text>
                        <strong> ({convertNumberToLetter(index)}) </strong>
                        <b>Passo {index + 1}:</b>
                        <div> {step.name} </div>
                      </Text>
                      
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Card>
        </Collapse>
      )}
    </div>
  );
}

export default CardTableRotasInformacoes;
