import {
  Badge,
  Box,
  Flex,
  Image,
  Text,
  Button,
  Collapse,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import CarouselComponent from "components/carousel/Carousel";
import React, { useRef } from "react";
import { getUrl } from "services/baseurl";
import BoxStars from "./BoxStars";
import TableUnitInformation from "./TableUnitInformation";
import { FaCamera } from "react-icons/fa";

function CardUnitInformation({ unit, reference, tableUnitInformationRef }) {
  const {
    name,
    description,
    photo,
    rating,
    category,
    street,
    street_view,
    visit,
  } = unit;

  const [showImage, setShowImage] = React.useState(false);

  const getImages = () => {
    return [
      {
        src: getUrl(photo),
        descricao: "Foto Principal",
      },
      {
        src: getUrl(street_view),
        descricao: "Street View",
      },
    ];
  };

  return (
    <Box ref={reference}>
      <Card direction="column" w="100%">
        <Box w="100%">
          <Text me="auto" fontSize="xl" fontWeight="700" lineHeight="100%">
            {name}
          </Text>
          <Text>{description}</Text>
          <Text>{street}</Text>
        </Box>

        <Flex mb="10px" p={1} overflow={"scroll"}>
          <BoxStars countStars={rating} />
          {unit.disabled && (
            <Badge
              ml={5}
              height={6}
              alignItems={"center"}
              backgroundColor={"gray.500"}
            >
              DESABILITADA
            </Badge>
          )}
          <Badge
            ml={5}
            p={2}
            height={6}
            alignItems={"center"}
            backgroundColor={"red.400"}
          >
            {category}
          </Badge>
          {visit.length == 0 && (
            <Badge
              ml={1}
              p={2}
              height={6}
              alignItems={"center"}
              backgroundColor={"green.400"}
            >
              NUNCA VISITADO
            </Badge>
          )}
        </Flex>

        <Button
          style={{
            height: "40px",
            textAlign: "center",
            textAlignLast: "center",
            textAlign: "center",
          }}
          onClick={() => setShowImage(!showImage)}
        >
          <FaCamera />
          <span style={{
            marginLeft: "10px"
          }}>Mostrar Imagens</span>
        </Button>

        <Collapse in={showImage} animateOpacity>
          <CarouselComponent images={getImages()} />
        </Collapse>

        <TableUnitInformation
          tableUnitInformationRef={tableUnitInformationRef}
          unit={unit}
        />

        <Box mt="auto"></Box>
      </Card>
    </Box>
  );
}

export default CardUnitInformation;
