import { useEffect, useState } from "react";
import { setCookie } from "utils/cookies";

const initialLoginState = localStorage.getItem('loginState') 
    ? JSON.parse(localStorage.getItem('loginState')) 
    : {
        user: null,
        token: null,
        isLogged: false,
    };
export default function useProvideAuth() {
    const [loginState, setLoginState] = useState(initialLoginState);

    useEffect(() => {
        localStorage.setItem("loginState", JSON.stringify(loginState));
    }, [loginState]);

    const signin = (token, user) => {
        setLoginState({
            user: user,
            isLogged: true,
            token: token
        })
        console.log('signin');

        return loginState;
    };
    
    const signout = cb => {
        setLoginState({
            user: null,
            isLogged: false,
            token: null
        })
        setCookie("mktstore", "", 0);
        console.log('signout');

        return loginState;
    };

    return {loginState, signin, signout};
}
