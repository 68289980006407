import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState, useRef, useContext } from "react";

import useVisitRepositoryGetOpenVisit from "repositories/useVisitRepositoryGetOpenVisit";
import getMinutesVisita, {
  calcularTempo,
} from "services/calcularDuracaoVisita";

import NotificationSound from "assets/audio/notification.mp3";
import {
  OpenVisitContext,
  SharedFunctionContext,
} from "contexts/prospeccao/IndexContext";
import { FaClock } from "react-icons/fa";

let intervalVisitTime;
let notificationTime = 900000; // 15 minutos em milisegundos

function CardTimeCurrentVisit({ tableUnitInformationRef }) {
  const [timeVisit, setTimeVisit] = useState(null);
  const [openVisit, setOpenVisit] = useContext(OpenVisitContext);
  const [stateSharedFunctions, setStateSharedFunctions] = useContext(
    SharedFunctionContext
  );
  const fetchOpenVisit = useVisitRepositoryGetOpenVisit();
  const audioPlayer = useRef(null);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
	console.log('useEffect', tableUnitInformationRef.current)
    if (
      tableUnitInformationRef.current &&
      tableUnitInformationRef.current.modalState
    ) {
		if(tableUnitInformationRef.current.modalState.id === 'visita' && tableUnitInformationRef.current.modalState.visible === true){
			setVisible(false);
		}else{
			setVisible(true);
		}
    }
  }, [tableUnitInformationRef.current]);

  useEffect(async () => {
    const usuario_id = 1;
    const openVisitResponse = await fetchOpenVisit(usuario_id);

    if (Object.keys(openVisitResponse).length) {
      setOpenVisit(openVisitResponse);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(openVisit).length) {
      triggerVisitTime();
    } else {
      clearInterval(intervalVisitTime);
      setTimeVisit(null);
    }
  }, [openVisit]);

  const triggerVisitTime = () => {
    let minutes = getMinutesVisita(openVisit.data_inicio_visita);

    setTimeVisit(minutes);
    setIntervalVisitTime();
  };

  const setIntervalVisitTime = () => {
    intervalVisitTime = setInterval(() => {
      let minutes = getMinutesVisita(openVisit.data_inicio_visita);
      let miliseconds = calcularTempo(openVisit.data_inicio_visita);
      setTimeVisit(minutes);

      if (miliseconds >= notificationTime) {
        playNotification(miliseconds);
      }
    }, 1000);
  };

  const handleClickFinalizarVisita = () => {
    if (
      tableUnitInformationRef.current &&
      tableUnitInformationRef.current.handleClickModal
    ) {
      tableUnitInformationRef.current.handleClickModal("visita");
    }
  };

  const playNotification = (visitTimeMiliseconds) => {
    const threeMinuteInMiliseconds = 180000;
    let currentNotificationTime = notificationTime;

    audioPlayer.current.play();

    if (currentNotificationTime < visitTimeMiliseconds) {
      currentNotificationTime = visitTimeMiliseconds;
    }

    notificationTime = currentNotificationTime + threeMinuteInMiliseconds;
  };

  if (!timeVisit) {
    return null;
  }

  if (visible === false) {
	return null;
  }

  return (
    <>
      <Box
        style={{
          position: "fixed",
          zIndex: 10000,
          bottom: "25px",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Flex>
            <Text
              fontSize={"32px"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 10,
              }}
            >
              <FaClock style={{ marginRight: 10 }} />

              {timeVisit}
            </Text>

            <Button
              style={{
                float: "right",
                marginLeft: "auto",
                marginRight: 0,
                marginTop: 10,
                marginBottom: 10,
              }}
              colorScheme="red"
              variant="outline"
              onClick={() => handleClickFinalizarVisita()}
            >
              Finalizar Visita
            </Button>
          </Flex>
        </div>
      </Box>
      <audio ref={audioPlayer} src={NotificationSound} />
    </>
  );
}

export default CardTimeCurrentVisit;
