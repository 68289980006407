import React from "react";

import Card from "components/card/Card";
import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import convertNumberToLetter from "services/numberToLetter";


function TabPontosParadas({pointsRoutes, clickNewPoint, clickDeletePoint}) {
  return (
    <Card>
      <Text fontSize="md" fontWeight="bold" textAlign={"center"} mb={5}>
        Pontos de Paradas da Rota
      </Text>

      <Divider />

      <div
        style={{
          height: "65vh",
          overflowY: "scroll",
        }}
      >
        <Flex
          p="40px"
          color="white"
          bg="teal.400"
          rounded="md"
          shadow="md"
          padding={2}
          width={300}
          height={"60px"}
          marginBottom={2}
          justifyContent={"space-between"}
        >
          <div>
            <Button
              className=""
              bg="teal.400"
              color={"white.500"}
              padding={0}
              m={0}
              disabled={true}
              title="(Desabilitado temporariamente) - Adição de Pontos por Rotas"
              onClick={() => clickNewPoint()}
            >
              <AddIcon />
            </Button>
          </div>
        </Flex>

        <Divider />

        {pointsRoutes.map((el, idx) => (
          <Flex
            key={idx}
            p="40px"
            color="white"
            bg="teal.400"
            rounded="md"
            shadow="md"
            padding={2}
            width={300}
            height={"80px"}
            marginBottom={2}
            justifyContent={"space-between"}
          >
            {/* name of point in Latter */}

            <div>
              <Text fontSize="md" fontWeight="bold">
                {convertNumberToLetter(idx)}
              </Text>

              <Button
                className=""
                bg="teal.400"
                color={"white.500"}
                padding={0}
                m={0}
                onClick={() => clickDeletePoint(el)}
              >
                <DeleteIcon />
              </Button>
            </div>
            <div>
              <Text fontSize="md" fontWeight="bold">
                {el.name}
              </Text>
            </div>
          </Flex>
        ))}
      </div>
    </Card>
  );
}

export default TabPontosParadas;
