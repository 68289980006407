import { BASE_URL } from "services/baseurl";
import { setCookie } from 'utils/cookies';

const useLoginPost = () => {
  const fetchPostLogin = async (data) => {
    const url = `${BASE_URL}login`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const responseJson = await response.json();

    if (responseJson.access_token) {
      setCookie("mktstore", responseJson.access_token, 1);
    }

    return responseJson;
  };

  return fetchPostLogin;
};

export default useLoginPost;