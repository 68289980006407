import {
    Text,
    Box
  } from "@chakra-ui/react";
  import React from "react";
  
  function ContentOpenedVisit() {
    return (
      <Box pb={5}>
        <Text align={"center"}>Há visita em aberto. Para visualizar outras unidades finalize a visita que está em andamento!</Text>
      </Box>
    );
  }
  
  export default ContentOpenedVisit;