import { useState, useEffect } from "react";
import { BASE_URL } from "services/baseurl";

const useRouteRepositoryGetRoutes = () => {

    return async (codigoCliente) => {
      const url = `${BASE_URL}routes?codigo_cliente=${codigoCliente}`;
      const response = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      });
      const responseJson = await response.json();
      return responseJson;
    };
  }

  const useRouteRepositoryPostRoutes = (codigoCliente, data) => {

    return async (codigoCliente, data) => {
      const url = `${BASE_URL}routes?codigo_cliente=${codigoCliente}`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const responseJson = await response.json();
      return responseJson;
    };
  }

  const useRouteRepositoryDeleteRoute = (codigoCliente, id) => {
    return async (codigoCliente, id) => {
      const url = `${BASE_URL}routes/${id}?codigo_cliente=${codigoCliente}`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
      const responseJson = await response.json();
      return responseJson;
    };
  }

  const useRouteRepositoryUpdateRoute = (codigoCliente, id, data) => {
    return async (codigoCliente, id, data) => {
      const url = `${BASE_URL}routes/${id}?codigo_cliente=${codigoCliente}`;
      const response = await fetch(url, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      const responseJson = await response.json();
      return responseJson;
    };
  }



export {
    useRouteRepositoryGetRoutes,
    useRouteRepositoryPostRoutes,
    useRouteRepositoryDeleteRoute,
    useRouteRepositoryUpdateRoute
    
};
