// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    initialData: {}
};

const slice = createSlice({
    name: 'businessMonster',
    initialState,
    reducers: {
        setupInitialData(state, action) {
            state.initialData = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setupInitialData(data) {
    return dispatch(slice.actions.setupInitialData(data));
}
