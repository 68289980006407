import { createContext } from "react";
import useProvideAuth from "../hooks/useProvideAuth";


export const authContext = createContext();


function ProvideAuth(props) {
  const auth = useProvideAuth();

  return (
    <authContext.Provider value={auth}>
      {props.children}
    </authContext.Provider>
  );
}

export default ProvideAuth;
