import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import GoogleMaps from "./GoogleMaps";
import { RouteContext } from "contexts/prospeccao/IndexContext";
import { useHistory, useLocation } from "react-router-dom";
import CardGoogleMaps from "./CardGoogleMaps";
import ListUnitRoute from "../ListUnitRoute";

function setError(error) {
  console.error(error);
}

function ContainerProspeccao({ unitsLocation, handleClickLocation }) {
  const browserLocation = useLocation();
  const history = useHistory();

  const [location, setLocation] = useState({
    latitude: -23.581988,
    longitude: -46.6520874,
  });
  const googleMapsRef = useRef(null);

  const [routes, setRoutes] = useContext(RouteContext);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    let search = new URLSearchParams(browserLocation.search);
    let routeId = search.get("rota");

    if (routeId && googleMapsRef.current && googleMapsRef.current.passRoute) {
      const route = routes.find((route) => route._id === routeId);
      
      setSelectedRoute(routeId);
      googleMapsRef.current.passRoute(route);
    }
  }, [googleMapsRef.current]);

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError("Geolocation is not supported");
      return;
    }
    let watcher = geo.watchPosition(function (position) {
      let newLocations = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      setLocation(newLocations);
    });
    return () => geo.clearWatch(watcher);
  }, []);

  const handleChangeRotasInput = (e) => {
    const routeId = e.target.value;
    if (routeId === "null") return;

    const route = routes.find((route) => route._id === routeId);
    googleMapsRef.current.passRoute(route);
    setSelectedRoute(routeId);
  };

  console.log(location);

  return (
    <Box>
      <Box
        p="10px"
        color="white"
        mt="4"
        bg="blue.500"
        rounded="md"
        shadow="md"
        mb={5}
      >
        <Flex>
          <Text fontSize="xl" fontWeight="bold" width={"100%"}>
            Rotas do Sistema
          </Text>

          <Select onChange={handleChangeRotasInput} value={selectedRoute}>
            <option value="null" selected disabled>
              Selecione uma Rota
            </option>
            {routes.map((route) => (
              <option key={route._id} value={route._id}>
                {route.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Box>
          {selectedRoute && (
            <Flex alignItems={"center"}>
              <Button
                colorScheme="teal"
                onClick={() => {
                  history.push(`/admin/rotas?rota=${selectedRoute}`);
                }}
              >
                <Text fontSize="xl" fontWeight="bold" width={"100%"}>
                  Detalhes
                </Text>
              </Button>

              <Badge
                ml={5}
                p={2}
                height={6}
                alignItems={"center"}
                backgroundColor={"teal.400"}
                style={{ cursor: "pointer" }}
              >
                {
                  routes.find((route) => route._id === selectedRoute).units
                    .length
                }{" "}
                Unidades
              </Badge>
            </Flex>
          )}
        </Box>
      </Box>

      <Tabs
        variant="soft-rounded"
        colorScheme="gray"
        onChange={(index) => setTabIndex(index)}
      >
        <Box
          p="10px"
          color="white"
          mt="4"
          bg="purple.500"
          rounded="md"
          shadow="md"
          mb={5}
        >
          <TabList>
            <Tab color={"white"}>Listagem da Rota</Tab>
            <Tab color={"white"}>Mapa da Rota</Tab>
          </TabList>
        </Box>
        <TabPanels>
          <TabPanel padding={0}>
            <ListUnitRoute
              location={location}
              unitsLocation={unitsLocation}
              handleClickLocation={handleClickLocation}
            />
          </TabPanel>
          <TabPanel padding={0}>
            <CardGoogleMaps
              location={location}
              unitsLocation={unitsLocation}
              handleClickLocation={handleClickLocation}
              googleMapsRef={googleMapsRef}
              selectedRoute={selectedRoute}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default ContainerProspeccao;
