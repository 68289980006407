import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useRouteRepositoryPostRoutes } from "repositories/useRouteRepositoryRoutes";
import routes from "routes";
import CardLoading from "../CardLoading";
import { RouteContext } from "contexts/prospeccao/IndexContext";
import { RouteDataContext } from "contexts/prospeccao/IndexContext";
import { UnitsRouteContext } from "contexts/prospeccao/IndexContext";
import { MapContext } from "contexts/prospeccao/IndexContext";
import { PointsRouteContext } from "contexts/prospeccao/IndexContext";

const initialFormState = {
  nome_rota: "",
};

function CriarNovaRotaModal({ units, route, handleClose }) {
  const [formState, setFormState] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    visible: false,
    message: "",
  });
  const [routePoints, setRoutePoints] = useContext(PointsRouteContext);
  const [unitsRoute, setUnitsRoute] = useContext(UnitsRouteContext);
  const [map, setMap] = useContext(MapContext);

  const createRoute = useRouteRepositoryPostRoutes();

  const handleSubmit = async () => {
    try {
      const idsUnits = unitsRoute.map((unit) => unit._id);
      const center = map.getCenter();

      setLoading(true);

      if (formState.nome_rota === "") {
        setErrorMessage({
          visible: true,
          message: "O campo identificador é obrigatório.",
        });
        return;
      }

      const data = {
        paths: routePoints,
        units: idsUnits,
        name: formState.nome_rota,
        routeRequest: null,
        directions: null,
        center: JSON.parse(JSON.stringify(center)),
      };

      console.log(data);

      await createRoute(4, data);

      setTimeout(() => {
        window.location.href = "#/admin/rotas";
        window.location.reload();
      }, 1000);

      handleClose();

     
    } catch (error) {
      setErrorMessage({
        visible: true,
        message: "Ocorreu um erro para criar a rota." + error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeForm = (event) => {
    let target = event.target.id;
    let value = event.target.value;
    setFormState({
      ...formState,
      ...{
        [target]: value,
      },
    });
  };

  if (loading) return <CardLoading />;

  return (
    <div>
      <Box mt={2} mb={2} pt={2}>
        <FormControl width={"100%"} as={SimpleGrid} columns={{ base: 2 }}>
          <FormLabel htmlFor="nome_rota">Identificador</FormLabel>
          <Input
            mb={2}
            id="nome_rota"
            value={formState.nome_rota}
            onChange={handleChangeForm}
            required
          />
        </FormControl>

        <Button colorScheme="green" onClick={handleSubmit}>
          Criar Rota
        </Button>

        {errorMessage.visible && (
          <Alert status="error" mt={3}>
            <p>{errorMessage.message}</p>
          </Alert>
        )}
      </Box>
    </div>
  );
}

export default CriarNovaRotaModal;
