import { useState, useEffect } from "react";
import { BASE_URL } from "services/baseurl";

const useGuidedAnalysisGetAnalysis = (unitId) => {
  const fetchGetAnalysis = async (unitId) => {
    const url = `${BASE_URL}guided_analysis?unit_id=${unitId}`;

    const response = await fetch(url);
    const responseJson = await response.json();
    return responseJson;
  };

  return fetchGetAnalysis;
};

export default useGuidedAnalysisGetAnalysis;
