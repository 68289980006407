import {
  Badge,
  Box,
  Button,
  Collapse,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { GlobalLoadingContext } from "contexts/prospeccao/IndexContext";
import React, { useContext } from "react";
import { SiGooglemybusiness } from "react-icons/si";
import BoxStars from "../BoxStars";

function CardTableRotasUnits({ units, }) {
  const { isOpen, onToggle } = useDisclosure();
  const [globalLoading, setGlobalLoading] = useContext(GlobalLoadingContext);

  const options = {
    'title': `Existe ${units.length} estabelecimentos disponíveis para essa rota.`,
    'text': 'Clique para ver as unidades disponíveis na rota',
    'bgColor': 'teal.500',
  }

  if (units.length == 0) {
    options.bgColor = 'red.500'
    options.title = 'Não há unidades disponíveis na rota '
    options.text = 'Posicione o ponto vermelho no mapa para o ponto de partida da rota.'
  }

  if (globalLoading) {
    options.bgColor = 'gray.500'
    options.title = 'Carregando...'
    options.text = 'Aguarde enquanto carregamos as unidades disponíveis para essa rota.'
  }

  

  return (
    <div>
      <Box p="40px" color="white" mt="4" bg={options.bgColor} rounded="md" shadow="md" onClick={onToggle} style={{cursor: 'pointer'}}>
        <Text fontSize="xl" fontWeight="bold">
          {options.title}
        </Text>
        <p>
          {options.text}
        </p>
      </Box>
      <Collapse in={isOpen} animateOpacity>
        {units.map((unit, index) => {
          return (
            <Card
              direction={{ base: "column", sm: "row" }}
              overflow="hidden"
              variant="outline"
              key={index}
              mb={3}
              mt={3}
            >
              <Image
                objectFit="cover"
                maxW={{ sm: "200px" }}
                maxH={{ sm: "200px" }}
                src={unit.photo}
                alt={unit.name}
                style={{ borderRadius: "5px" }}
              />

              <Stack>
                <Card>
                  <Heading size="md">
                    {unit.name}
                    <Badge
                      ml={5}
                      p={2}
                      height={6}
                      alignItems={"center"}
                      backgroundColor={"red.400"}
                    >
                      {unit.category}
                    </Badge>
                  </Heading>
                  <Text py="2">{unit.full_address}</Text>
                  <BoxStars countStars={unit.rating} />
                </Card>

                <hr />
                <a target="__blank" href={unit.location_link}>
                  <Button title="Link para a ficha do Google Meu Negócio">
                    <Icon
                      w="24px"
                      h="24px"
                      me="5px"
                      color={"black.500"}
                      as={SiGooglemybusiness}
                    />
                  </Button>
                </a>
              </Stack>
            </Card>
          );
        })}
      </Collapse>
    </div>
  );
}

export default CardTableRotasUnits;
