/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/logo_nm_center.png";
import { MdOutlineRemoveRedEye, MdEmail, MdPhoneIphone } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useMask } from "@react-input/mask";
import { useHistory, useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";
import useLoginPost from "repositories/useLoginPost";
import { BiLoaderCircle } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const useLogin = useLoginPost();

  const history = useHistory();
  const auth = useAuth();

  const inputRef = useMask({ mask: '(__) _____-____', replacement: { _: /\d/ } });

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [loginEmail, setLoginEmail] = useState(false);
  const handleChangeLogin = () => {
    setLoginEmail(!loginEmail);
    setEmail('');
    setCelular('');
  }

  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');
  const [password, setPassword] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmmit = async e => {
    e.preventDefault();

    const data = {
      password,
    };

    if (email.length) {
      data.email = email;
    }
    if (celular.length) {
      data.celular = celular;
    }

    setIsProcessing(true)
    await useLogin(data)
      .then(data => {
        setIsProcessing(false);

        if (data.message) {
          toast.error(data.message)
        }
    
        auth.signin(data.access_token, data.user)

      })
      .catch(error => {
        setIsProcessing(false);
        toast.error('Algo deu errado')
      });  
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <ToastContainer />
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Login
          </Heading>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <form onSubmit={handleSubmmit}>
            <FormControl>
              <Flex gap={'8px'}>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Email ou Celular<Text color={brandStars}>*</Text>
                </FormLabel>
                <FormLabel htmlFor='email-alerts' mb='0'
                  display='flex'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                >
                  {loginEmail ? 'Logar com celular' : 'Logar com email'}
                </FormLabel>
                <IconButton
                  aria-label="toggle theme"
                  rounded="full"
                  bgColor={'#422AFB'}
                  color={'white'}
                  size="sm"
                  bottom={2}
                  onClick={handleChangeLogin} icon={loginEmail ? <MdPhoneIphone /> : <MdEmail />}
                />
              </Flex>
              { loginEmail
                  ? <Input
                      name="email"
                      value={email}
                      isRequired={true}
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      type='email'
                      placeholder='mail@monster.com'
                      mb='24px'
                      fontWeight='500'
                      size='lg'
                      onChange={email => setEmail(email.target.value)}
                    />
                  : <Input
                      name="celular"
                      value={celular}
                      isRequired={true}
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      type='phone'
                      placeholder='(11) 90000-0000'
                      mb='24px'
                      fontWeight='500'
                      size='lg'
                      ref={inputRef}
                      onChange={celular => setCelular(celular.target.value)}
                    />
              }
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Senha<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 6 characters'
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                  onChange={password => setPassword(password.target.value)}
                  value={password}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {isProcessing
                ? <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'
                    disabled={true}
                  >
                    <BiLoaderCircle style = {{transform: 'rotate(90deg)' }} />
                  </Button>
                : <Button
                    type="submit"
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'
                  >
                    Logar
                  </Button>
              }
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
