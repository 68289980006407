import { BASE_URL } from "services/baseurl";

const useResetPasswordPost = () => {
  const fetchPostResetPassword = async (data) => {
    const url = `${BASE_URL}reset-password`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const responseJson = await response.json();

    return responseJson;
  };

  return fetchPostResetPassword;
};

export default useResetPasswordPost;