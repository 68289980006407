import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import GoogleMapsAllRoutes from "../GoogleMaps/GoogleMapsAllRoutes";
import Card from "components/card/Card";

function AllMapRoutesPage({ routes, handleClickNewRotas, handleClickListagemRotas }) {
  return (
    <Box>
      <Box
        p="40px"
        color="white"
        mt="4"
        bg="teal.500"
        rounded="md"
        shadow="md"
        mb={5}
        display="flex"
      >
        <Text fontSize="xl" fontWeight="bold">
          Rotas do Sistema
        </Text>

        <Button
          colorScheme={"green"}
          style={{ position: "absolute", right: 100 }}
          onClick={handleClickNewRotas}
        >
          <Text fontSize="xl" fontWeight="bold">
            Criar Rota
          </Text>
        </Button>

        <Button
          colorScheme={"purple"}
          style={{ position: "absolute", right: 300 }}
          onClick={() => {
            handleClickListagemRotas();
          }}
        >
          <Text fontSize="xl" fontWeight="bold">
            Listagem de Rotas
          </Text>
        </Button>
      </Box>

      <Card direction="column" w="100%" p={2} pt={5}>
        <Flex>
          <GoogleMapsAllRoutes routes={routes} />
        </Flex>
      </Card>
    </Box>
  );
}

export default AllMapRoutesPage;
