import React, { useContext } from "react";

import Card from "components/card/Card";
import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  DeleteIcon,
  UpDownIcon,
} from "@chakra-ui/icons";
import convertNumberToLetter from "services/numberToLetter";
import { IoRefreshCircleOutline } from "react-icons/io5";
import { MdOutlineUpdate, MdUpdateDisabled } from "react-icons/md";
import { UnitsRouteContext } from "contexts/prospeccao/IndexContext";

function TabUnits({ units, clickDeletePoint, handleRestartPosition }) {
  const [unitsRoute, setUnitsRoute] = useContext(UnitsRouteContext);

  const handleUpPosition = (unit) => {
    console.log("handleUpPosition", unit);
  
    const currentIndex = units.findIndex((item) => item._id === unit._id);
  
    if (currentIndex === -1) {
      return;
    }
  
    // Verifica se o elemento já está na primeira posição
    if (currentIndex === 0) {
      return;
    }
  
    // Adiciona o elemento 1 posição acima no array
    const newUnits = [...units];
    const temp = newUnits[currentIndex];
    newUnits[currentIndex] = newUnits[currentIndex - 1];
    newUnits[currentIndex - 1] = temp;
  
    // Atualiza o order dos elementos
    newUnits.forEach((item, index) => {
      item.order = index + 1;
    });
  
    setUnitsRoute(newUnits);
  };

  const handleDownPosition = (unit) => {
    console.log("handleDownPosition", unit);

    const currentIndex = units.findIndex((item) => item._id === unit._id);
  
    if (currentIndex === -1) {
      return;
    }
  
    // Verifica se o elemento já está na última posição
    if (currentIndex === units.length - 1) {
      return;
    }
  
    // Adiciona o elemento 1 posição abaixo no array
    const newUnits = [...units];
    const temp = newUnits[currentIndex];
    newUnits[currentIndex] = newUnits[currentIndex + 1];
    newUnits[currentIndex + 1] = temp;
  
    // Atualiza o order dos elementos
    newUnits.forEach((item, index) => {
      item.order = index + 1;
    });
  
    setUnitsRoute(newUnits);
  };

  const clickRestartPosition = async () => {
    await handleRestartPosition();
  };

  return (
    <Card>
      <Text fontSize="md" fontWeight="bold" textAlign={"center"} mb={5}>
        Units
      </Text>

      <Divider />

      <div>
        <Flex
          p={2}
          color="white"
          bg="purple.400"
          rounded="md"
          shadow="md"
          padding={2}
          width={300}
          height={"60px"}
          marginBottom={2}
          justifyContent={"space-between"}
        >
          <div>
            <Button
              className=""
              bg="purple.400"
              color={"white.500"}
              padding={0}
              m={0}
              onClick={() => clickRestartPosition()}
            >
              <MdOutlineUpdate size={30} />
            </Button>
          </div>
        </Flex>
      </div>

      <div
        style={{
          height: "60vh",
          overflowY: "scroll",
        }}
      >
        {units.map((el, idx) => (
          <Flex
            key={idx}
            p="40px"
            color="white"
            bg="teal.400"
            rounded="md"
            shadow="md"
            padding={2}
            width={300}
            height={"80px"}
            marginBottom={2}
            justifyContent={"space-between"}
          >
            {/* name of point in Latter */}

            <div>
              <Text fontSize="md" fontWeight="bold">
                {el.name.toUpperCase().substring(0, 20)}
              </Text>

              <Button
                className=""
                bg="teal.400"
                color={"white.500"}
                padding={0}
                m={0}
                onClick={() => clickDeletePoint(el)}
              >
                <DeleteIcon />
              </Button>

              <Button
                className=""
                bg="teal.400"
                color={"white.500"}
                padding={0}
                m={0}
                onClick={() => handleDownPosition(el)}
              >
                <ArrowDownIcon />
              </Button>
              <Button
                className=""
                bg="teal.400"
                color={"white.500"}
                padding={0}
                m={0}
                onClick={() => handleUpPosition(el)}
              >
                <ArrowUpIcon />
              </Button>
            </div>

            <div>
              <Text fontSize="md" fontWeight="bold">
                {idx + 1}
              </Text>
            </div>
          </Flex>
        ))}
      </div>
    </Card>
  );
}

export default TabUnits;
