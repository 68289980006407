import { getCookie } from "utils/cookies";
import { setCookie } from "utils/cookies";


const isPublicUrl = (url) => {
  return url.includes("login") || url.includes("forgot-password") || url.includes("reset-password");
}

const handleKeepPage = () => {
  const url = window.location.href;
  const path = url.split("/").pop();
  console.log(path)
  if (path === '') {
    window.location.href = "/login";
    return;
  }
  const token = getCookie("mktstore");
  if (token) return;

  console.log(url)
  if (isPublicUrl(url)) return;  
  window.location.href = "/login";
}

export function authHandler() {
  const originalFetch = window.fetch;

  window.fetch = async function (url, options) {
    let headers = options?.headers || {};
    const token = getCookie("mktstore");
    if (token && token.length > 0) {
      headers.Authorization = `Bearer ${token}`;
    }
    headers.Accept = "application/json";
    headers = {
      ...headers,
    };

    const response = await originalFetch(url, options);
    if (response.status === 401) {
      window.location.href = "/login";
      console.log("401");
      setCookie("mktstore", "", 0);
    }
    return response;
  };


  handleKeepPage();
  

}
