import React from "react";
import { Button, Icon } from "@chakra-ui/react";
import { MdCheckCircle, MdOutlineError } from "react-icons/md";
import { BiLinkExternal } from "react-icons/bi";

export const IconSuccess = (
  <Icon w="24px" h="24px" me="5px" color={"green.500"} as={MdCheckCircle} />
);

export const IconError = (
  <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdOutlineError} />
);

export const IconExternal = ({ onClick }) => (
  <Button onClick={onClick}>
    <Icon w="24px" h="24px" me="5px" color={"red.500"} as={BiLinkExternal} />
  </Button>
);
