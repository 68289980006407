import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function CarouselComponent({ images }) {
  return (
    <Carousel showArrows={true} showThumbs={false}>
      {images.map((image, index) => {
        return (
          <div key={index} style={{height: "460px"}}>
            <img src={image.src} />
            <p className="legend">{image.descricao}</p>
          </div>
        );
      })}
    </Carousel>
  );
}

export default CarouselComponent;
