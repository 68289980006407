import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useRouteRepositoryDeleteRoute } from "repositories/useRouteRepositoryRoutes";
import routes from "routes";
import CardLoading from "../CardLoading";
import { RouteContext } from "contexts/prospeccao/IndexContext";
import { RouteDataContext } from "contexts/prospeccao/IndexContext";
import { UnitsRouteContext } from "contexts/prospeccao/IndexContext";
import { MapContext } from "contexts/prospeccao/IndexContext";
import { PointsRouteContext } from "contexts/prospeccao/IndexContext";

const initialFormState = {
  nome_rota: "",
};

function DeleteNovaRotaModal({ route, handleClose }) {
  const [formState, setFormState] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    visible: false,
    message: "",
  });

  const handleChangeForm = (event) => {
    let target = event.target.id;
    let value = event.target.value;
    setFormState({
      ...formState,
      ...{
        [target]: value,
      },
    });
  };

  const deleteRoute = useRouteRepositoryDeleteRoute();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await deleteRoute(4, route._id);

      setTimeout(() => {
        window.location.href = '#/admin/rotas'
        window.location.reload();
      }, 1000); 
      handleClose();
      
     
      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CardLoading />;

  return (
    <div>
      <Box mt={2} mb={2} pt={2}>
        

        <Alert status="info" mb={2}>
          <p>
            Você está prestes a excluir a rota <strong>{route.name}</strong>.
          </p>
        </Alert>

        <Alert status="error" mb={2}>
          <p>
            Esta ação não poderá ser desfeita. Caso queira prosseguir, digite o
            identificador da rota abaixo e clique em "Excluir a rota".
          </p>
        </Alert>

        <FormControl width={"100%"} as={SimpleGrid} columns={{ base: 2 }}>
          <FormLabel htmlFor="nome_rota">Identificador</FormLabel>
          <Input
            mb={2}
            id="nome_rota"
            value={formState.nome_rota}
            onChange={handleChangeForm}
            required
          />
        </FormControl>

        <Button
          disabled={formState.nome_rota !== route.name}
          colorScheme="red"
          onClick={handleSubmit}
        >
          Excluir a rotas
        </Button>
      </Box>
    </div>
  );
}

export default DeleteNovaRotaModal;
