import { useState, useEffect } from "react";
import { BASE_URL } from "services/baseurl";

const useCategoryRepositoryGetCategories = () => {
  return async () => {
    const url = `${BASE_URL}categories`;
    const response = await fetch(url);
    const responseJson = await response.json();
    return responseJson;
  };
};

export default useCategoryRepositoryGetCategories;
