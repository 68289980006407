import { createContext } from "react";
import React from "react";

const HandleClickLocationContext = createContext(null);
const SelectedLocationContext = createContext(null);
const GlobalLoadingContext = createContext(null);
const UnitsContext = createContext(null);
const CategoriesContext = createContext(null);
const OpenVisitContext = createContext(null);
const MapContext = createContext(null);
const RouteContext = createContext(null);
const PointsRouteContext = createContext(null);
const RouteDataContext = createContext(null);
const UnitsRouteContext = createContext(null);
const AlertGlobalContext = createContext(null);

const CenterLocationContext = createContext(null);
const SharedFunctionContext = createContext(null);

function IndexContext({
  children,
  stateUnits,
  stateSelectedLocation,
  stateGlobalLoading,
  handleClickLocation,
  categories,
  stateOpenVisit,
  stateMap,
  stateRoute,
  statePointsRoute,
  stateCenterLocation,
  stateRouteData,
  stateUnitsRoute,
  stateAlertGlobal,
  stateSharedFunctions,
}) {
  return (
    <UnitsContext.Provider value={stateUnits}>
      <MapContext.Provider value={stateMap}>
        <AlertGlobalContext.Provider value={stateAlertGlobal}>
          <CenterLocationContext.Provider value={stateCenterLocation}>
            <PointsRouteContext.Provider value={statePointsRoute}>
              <SelectedLocationContext.Provider value={stateSelectedLocation}>
                <GlobalLoadingContext.Provider value={stateGlobalLoading}>
                  <SharedFunctionContext.Provider value={stateSharedFunctions}>
                    <HandleClickLocationContext.Provider
                      value={handleClickLocation}
                    >
                      <UnitsRouteContext.Provider value={stateUnitsRoute}>
                        <CategoriesContext.Provider value={categories}>
                          <RouteDataContext.Provider value={stateRouteData}>
                            <RouteContext.Provider value={stateRoute}>
                              <OpenVisitContext.Provider value={stateOpenVisit}>
                                {children}
                              </OpenVisitContext.Provider>
                            </RouteContext.Provider>
                          </RouteDataContext.Provider>
                        </CategoriesContext.Provider>
                      </UnitsRouteContext.Provider>
                    </HandleClickLocationContext.Provider>
                  </SharedFunctionContext.Provider>
                </GlobalLoadingContext.Provider>
              </SelectedLocationContext.Provider>
            </PointsRouteContext.Provider>
          </CenterLocationContext.Provider>
        </AlertGlobalContext.Provider>
      </MapContext.Provider>
    </UnitsContext.Provider>
  );
}

export default IndexContext;
export {
  SelectedLocationContext,
  UnitsContext,
  HandleClickLocationContext,
  CategoriesContext,
  OpenVisitContext,
  GlobalLoadingContext,
  SharedFunctionContext,
  MapContext,
  RouteContext,
  PointsRouteContext,
  CenterLocationContext,
  RouteDataContext,
  UnitsRouteContext,
  AlertGlobalContext,
};
