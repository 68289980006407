import { useState, useEffect } from "react";
import { BASE_URL } from "services/baseurl";

const useUnitRepositoryPutUnit = () => {
  return async (idUnit, data) => {
    const url = `${BASE_URL}units/${idUnit}`;
    const response = await fetch(url, {
      'body': JSON.stringify(data), 
      'headers': {'Accept': 'application/json', 'Content-Type': 'application/json'}, 
      'method': 'PUT'
    });
    const responseJson = await response.json();
    return responseJson;
  };
};

export default useUnitRepositoryPutUnit;
