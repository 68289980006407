'use client';

import ThemeCustomization from "themes";

import Calendar from 'components/calendar/Calendar';

const Agenda = () => {
    return (
        <ThemeCustomization>
          <Calendar></Calendar>
        </ThemeCustomization>
    );
};

export default Agenda;
