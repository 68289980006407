import useAuth from 'hooks/useAuth';
import { Route, Redirect } from 'react-router-dom';


// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ component: Component, ...rest }) => {
    const auth = useAuth();

    return(
        <Route {...rest} render={(props) => (
            auth.loginState.user
                ? <Component {...props} />
                : <Redirect to='/login' />
        )} />
    )
}

export default AuthGuard;
