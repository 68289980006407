import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import BoxStars from "./BoxStars";

function ItemLocationCard({ location, clickLocation, route }) {
  return (
    <Card direction={{ base: "column", sm: "row" }} mb={{ base: "3" }}>
      <Text sm="sm" fontWeight="bold" fontSize={11}>
        {route.name}-{location.order}
      </Text>
      <Flex direction={{ base: "column", sm: "row" }} mb={{ base: "3" }}>
        <Stack>
          <Heading
            m={2}
            size="lg"
            bg={"purple.400"}
            style={{
              color: "#FFFFFF",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              textAlign: "center",
              textAlignLast: "center",
              textAlign: "center",
            }}
          >
            {location.order}
          </Heading>
        </Stack>

        <Stack ml={{ base: "0", sm: "5" }} mt={{ base: "5", sm: "0" }}>
          <Heading size="sm">{location.name}</Heading>
          <Text fontSize="sm">{location.full_address}</Text>
        </Stack>
      </Flex>
      <Flex mb={"2"} p={1} overflow={"scroll"}>
        {location.visit.length === 0 ? (
          <Badge
            ml={1}
            p={2}
            height={6}
            alignItems={"center"}
            backgroundColor={"green.400"}
          >
            NUNCA VISITADO
          </Badge>
        ) : (
          <Badge
            ml={1}
            p={2}
            height={6}
            alignItems={"center"}
            backgroundColor={"yellow.400"}
          >
            VISITADO
          </Badge>
        )}

        <Box ml={2}>
          <BoxStars countStars={location.rating} />
        </Box>
      </Flex>

      <Box mt="auto">
        <Button
          bg="gray.500"
          color={"white"}
          onClick={() => clickLocation(location._id)}
        >
          Ver Mais
        </Button>
      </Box>
    </Card>
  );
}

export default ItemLocationCard;
