import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { AlertGlobalContext } from "contexts/prospeccao/IndexContext";
import React, { useContext, useEffect } from "react";

function AlertGlobal() {
  const [alertGlobal, setAlertGlobal] = useContext(AlertGlobalContext);

  useEffect(() => {
    setTimeout(() => {
      if (alertGlobal.visible)
        setAlertGlobal({ ...alertGlobal, visible: false });
    }, alertGlobal.time);
  }, [alertGlobal]);

  return (
    <div>
      {alertGlobal.visible && (
        <Alert
          status={alertGlobal.type}
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="150px"
          style={{
            top: "25%",
            position: "absolute",
            zIndex: 9999,
          }}
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {alertGlobal.message}
          </AlertTitle>
        </Alert>
      )}
    </div>
  );
}

export default AlertGlobal;
