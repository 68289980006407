import CardLoading from "components/prospeccao/CardLoading";
import CardUnitInformation from "components/prospeccao/CardUnitInformation";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import useUnitRepositoryGetUnit from "repositories/useUnitRepositoryGetUnit";
import { useUnitRepositoryGetUnitsByLatLong } from "repositories/useUnitRepositoryGetUnits";
import useCategoryRepositoryGetCategories from "repositories/useCategoryRepositoryGetCategories";
import IndexContext from "contexts/prospeccao/IndexContext";
import CardGoogleMapsRotas from "components/prospeccao/rotas/CardGoogleMapsRotas";
import AlertGlobal from "components/alert/AlertGlobal";

function RotasPage() {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [pointRoute, setPointRoute] = useState([]);
  const [map, setMap] = useState(null);
  const [categories, setCategories] = useState([]);
  const [openVisit, setOpenVisit] = useState({});
  const [modalState, setModalState] = useState({ id: null, visible: false });
  const fetchUnits = useUnitRepositoryGetUnitsByLatLong();
  const fetchUnit = useUnitRepositoryGetUnit();
  const fetchCategories = useCategoryRepositoryGetCategories();
  const cardUnitRef = useRef(null);
  const [centerLocation, setCenterLocation] = useState(null);
  const [routeData, setRouteData] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [unitsRoute, setUnitsRoute] = useState([]);
  const [alertGlobal, setAlertGlobal] = useState({
    visible: false,
    message: "",
    type: "error",
    time: 3000
  });
  const [stateSharedFunctions, setStateSharedFunctions] = useState({});

  

  useEffect(async () => {
    
  }, []);

  

  const handleClickLocation = async (locationId, firstLoad) => {
    
  };

  return (
    <>
      <IndexContext
        stateUnits={[units, setUnits]}
        stateSelectedLocation={[selectedLocation, setSelectedLocation]}
        handleClickLocation={handleClickLocation}
        categories={categories}
        stateOpenVisit={[openVisit, setOpenVisit]}
        stateGlobalLoading={[globalLoading, setGlobalLoading]}
        stateMap={[map, setMap]}
        stateCenterLocation={[centerLocation, setCenterLocation]}
        statePointsRoute={[pointRoute, setPointRoute]}
        stateRoute={[routes, setRoutes]}
        stateRouteData={[routeData, setRouteData]}
        stateUnitsRoute={[unitsRoute, setUnitsRoute]}
        stateAlertGlobal={[alertGlobal, setAlertGlobal]}
        stateSharedFunctions={[
          stateSharedFunctions,
          setStateSharedFunctions,
        ]}
      >

        <AlertGlobal />
        <CardGoogleMapsRotas
          unitsLocation={units || []}
          handleClickLocation={handleClickLocation}
        />

      </IndexContext>
    </>
  );
}

export default RotasPage;
