import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import calcularInformacaoEssenciais from "services/calcularInformacaoEssenciais";
import { IconError, IconSuccess } from "./icons";

function ContentInformarcaoEssenciais({ unit }) {
  let info = calcularInformacaoEssenciais(unit);

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Horário de Funcionamento</Td>
            <Td>
              {info.infos.horario_funcionamento ? IconSuccess : IconError}
            </Td>
          </Tr>
          <Tr>
            <Td>Telefone</Td>
            <Td>{info.infos.telefone ? IconSuccess : IconError}</Td>
          </Tr>
          <Tr>
            <Td>Site</Td>
            <Td>{info.infos.site ? IconSuccess : IconError}</Td>
          </Tr>
          <Tr>
            <Td>Descrição da Empresa</Td>
            <Td>{info.infos.descricao ? IconSuccess : IconError}</Td>
          </Tr>
          <Tr>
            <Td>Foto da Capa</Td>
            <Td>{info.infos.foto_capa ? IconSuccess : IconError}</Td>
          </Tr>
          <Tr>
            <Td>Avaliações</Td>
            <Td>{info.infos.avaliacao ? IconSuccess : IconError}</Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
}

export default ContentInformarcaoEssenciais;
