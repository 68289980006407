import useAuth from 'hooks/useAuth';
import { Route, Redirect } from 'react-router-dom';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ component: Component, ...rest }) => {
    const auth = useAuth();

    return(
        <Route {...rest} render={(props) => (
            auth.loginState.user
                ? <Redirect to='/admin' />
                : <Component {...props} />
        )} />
    )
}

export default GuestGuard;
