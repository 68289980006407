import {
  Avatar,
  Icon,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { greatPlaceStyle } from "./marker";
import { IoMan } from "react-icons/io5";

function MeLocationMarker({ location }) {
  const style = greatPlaceStyle;

  return (
    // <div className="hint hint--html hint--info hint--top" style={style}>
    <Avatar
      _hover={{ cursor: "pointer" }}
      color="white"
      bg={useColorModeValue("brand.400")}
      icon={<Icon as={IoMan} width={"90%"} height={"100%"} />}
      size="sm"
      w="20px"
      h="20px"
    />
    // </div>
  );
}

export default MeLocationMarker;
